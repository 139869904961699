<template>
  <v-row justify="space-between">
    <v-col cols="12" :sm="setLeftColWidthSmAndUp">
      <div>
        <div v-if="headerText || $slots.header" :class="headerClass ? headerClass : 'header mb-5'">
          <slot name="header">
            {{ headerText }}
          </slot>
        </div>
        <div class="subheader mb-1">
          <slot name="subheader" />
        </div>
      </div>
    </v-col>

    <v-col
      v-if="outlinedButtonText || regularButtonText"
      cols="12"
      :sm="outlinedButtonText && regularButtonText ? 6 : 5"
    >
      <div class="d-flex flex-wrap justify-center justify-sm-end">
        <slot name="outlined-button">
          <v-btn
            v-if="outlinedButtonText && outlinedButtonVisible"
            class="my-1 page-header-btn"
            width="185px"
            height="46px"
            :block="$vuetify.breakpoint.xsOnly"
            tile
            outlined
            :color="outlinedButtonColor"
            @click="$emit('outlined-button-click')"
          >
            {{ outlinedButtonText }}
          </v-btn>
        </slot>
        <slot name="regular-button">
          <v-btn
            v-if="regularButtonText && regularButtonVisible"
            class="my-1 ml-sm-2 page-header-btn"
            width="185px"
            height="46px"
            tile
            color="primary"
            :block="$vuetify.breakpoint.xsOnly"
            @click="$emit('regular-button-click')"
          >
            {{ regularButtonText }}
          </v-btn>
        </slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    headerText: {
      default: () => {},
      type: String,
    },
    headerClass: {
      type: String,
      default: ''
    },
    outlinedButtonText: {
      default: () => null,
      type: String,
    },
    regularButtonText: {
      default: () => null,
      type: String,
    },
    regularButtonVisible: {
      type: Boolean,
      default: true,
    },
    outlinedButtonVisible: {
      type: Boolean,
      default: true,
    },
    outlinedButtonColor: {
      type: String,
      default: 'black',
    },
  },
  computed: {
    setLeftColWidthSmAndUp() {
      if (this.outlinedButtonText && this.regularButtonText) {
        return 6;
      } else if (this.outlinedButtonText || this.regularButtonText) {
        return 7;
      } else return 12;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #241c15;
}
.subheader {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
  a {
    font-weight: 600;
    color: #2b84eb;
    text-decoration: none;
  }
}

.page-header-btn {
  font-size: 14px !important;
}

::v-deep .page-header-btn .v-btn__content {
  font-size: 13px !important;
}

button.v-btn.btn-primary {
  background-color: #2b84eb !important;
}

@media (max-width: 727px) {
  .header {
    font-size: 32px;
  }

  .subheader {
    font-size: 14px;
  }
}
</style>
