<template>
  <component :is="nodeType" :node="node" :segments="segments" />
</template>

<script>
  import TriggerSegmentNode from "./nodes/TriggerSegmentNode";
  import ActionSendEmailNode from "./nodes/ActionSendEmailNode";
  import ActionSendSplitTestEmailNode from "./nodes/ActionSendSplitTestEmailNode";
  import ActionAutoResendEmailNode from "./nodes/ActionAutoResendEmailNode";
  import ActionUpdateFieldNode from "./nodes/ActionUpdateFieldNode";
  import ActionAddDelayNode from "./nodes/ActionAddDelayNode";
  import ActionAddContactToSegmentNode from "./nodes/ActionAddContactToSegmentNode";
  import ActionRemoveContactFromSegmentNode from "./nodes/ActionRemoveFromSegmentNode";
  import ActionEjectNode from "./nodes/ActionEjectNode";
  import ActionSendDirectMailNode from "./nodes/ActionSendDirectMailNode";
  import ActionSendSmsNode from "./nodes/ActionSendSmsNode";
  import ConditionCheckEmailStatusNode from "./nodes/ConditionCheckEmailStatusNode";
  import ConditionIsInSegmentNode from "./nodes/ConditionIsInSegmentNode";
  import ConditionCheckFieldNode from "./nodes/ConditionCheckFieldNode";

  export default {
    name: "CampaignNode",
    components: {
      TriggerSegmentNode,
      ActionSendEmailNode,
      ActionSendSplitTestEmailNode,
      ActionAutoResendEmailNode,
      ActionUpdateFieldNode,
      ActionAddDelayNode,
      ActionAddContactToSegmentNode,
      ActionRemoveContactFromSegmentNode,
      ActionEjectNode,
      ActionSendDirectMailNode,
      ActionSendSmsNode,
      ConditionCheckEmailStatusNode,
      ConditionIsInSegmentNode,
      ConditionCheckFieldNode,
    },
    props: {
      node: {
        type: Object,
        required: true,
      },
      segments: {
        type: Array,
        default: () => [],
      }
    },
    computed: {
      nodeType() {
        return this.node.type + this.node.node_type + 'Node';
      },
    },
  }
</script>
