<template>
  <div class="mb-3">
    <div class="notification-menu__header">
      Segment
    </div>
    <CustomDropdown
      autocomplete
      placeholder="Select an Option"
      class="mt--2"
      :value="configuration.segmentId"
      :items="segments"
      item-value="id"
      item-text="name"
      @change="change_segmentId"
    />
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "ConditionIsInSegmentConfiguration",
  components: {
    CustomDropdown,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    segments: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    change_segmentId(val) {
      const segmentName = this.segments.find((x) => x.id === val)?.name;
      this.$emit("update:configuration", 
        {
          ...this.configuration,
          segmentId: val,
          name: segmentName ?? ''
        }
      );
    }
  },
};
</script>
