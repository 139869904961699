<template>
  <div />
</template>

<script>
    export default {
        name: "ActionEjectConfiguration",
        props: {
            configuration: {
                type: Object,
                required: true,
            },
        },
    }
</script>
