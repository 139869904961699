<template>
  <v-group
    ref="node"
    :config="nodeConfig"
    @dragstart="onDragStart"
    @dragmove="onDragging"
    @dragend="onDragEnd"
    @click="onSelect"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <v-rect
      :config="parentConfig"
      @dblclick="openConfiguration"
    />
    <v-image
      :config="removeIconConfig"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="onRemoveClick"
    />
    <v-group
      v-if="!isHovered"
      :config="detailsConfig"
    >
      <v-text :config="nodeNameConfig" />
      <v-text :config="dbNodeConfig" />
      <div v-if="!isShowCounts">
        <v-image
          v-if="nodeImageUrl"
          :will-read-frequently="true"
          :config="nodeIconConfig"
        />
        <v-text :config="nodeLabelConfig" />
      </div>
      <div v-else>
        <v-text :config="nodeCountEnteredLabelConfig" />
        <v-text :config="nodeCountProcessingLabelConfig" />
        <v-text :config="nodeCountCompletedLabelConfig" />
        <v-text :config="nodeCountEndedLabelConfig" />
      </div>
    </v-group>
    <NodeHoverConfiguration
      v-else
      @onConfig="openConfiguration"
      @onDelete="onRemoveClick"
    />
    <v-group
      v-if="!readOnly && !isShowCounts"
      @mouseenter="onTransitionBarHover"
      @mouseleave="onTransitionBarLeave"
    >
      <v-rect ref="transitionBar" :config="transitionBarConfig" />
      <v-text :config="transitionTextConfig" />
    </v-group>
    <NodeStatistics :node="node" />
  </v-group>
</template>

<script>
import CampaignNodeMixin from "@/mixins/campaign-node-mixin";
import CampaignMixin from "@/mixins/campaign-mixin";
import NodeStatistics from "./NodeStatistics";
import NodeHoverConfiguration from "@/views/Campaigns/components/automation/NodeHoverConfiguration";

export default {
  name: "ActionSendDirectMailNode",
  components: {NodeHoverConfiguration, NodeStatistics},
  mixins: [CampaignNodeMixin, CampaignMixin],
  data() {
    return {
      transitionBarColor: '#7a5499',
      vendor: null,
      creative: null,
      defaultTransitionHoverEvent: 'mailed',
      vendors: this.$store.getters['user/directMailAutomationVendors'],
    };
  },
  computed: {
    nodeImageUrl() {
      if (!this.vendors || !this.node?.config?.vendor) {
        return null;
      }

      return this.vendors.find(vendor => vendor.id === this.node.config.vendor)?.icon;
    },
  },
  mounted() {
    if (this.nodeImageUrl) {
      this.nodeIconObj.src = this.nodeImageUrl;
    }
  },
}
</script>
