<template>
  <div>
    <div class="mb-3">
      <CustomDropdown
        autocomplete
        header="Vendor"
        placeholder="Select an Option"
        class="mt--2"
        :value="configuration.vendor"
        :items="vendors"
        :readonly="vendors.length === 1"
        required
        item-value="id"
        item-text="name"
        @change="
          $emit('update:configuration', {
            ...configuration,
            vendor: $event.id,
          })
        "
      />
    </div>
    <div class="mb-3">
      <CustomTextarea
        header="Message Body"
        placeholder="Enter the message body"
        :value="configuration.messageBody"
        :hint="messageBodyHint"
        required
        @input="
          $emit('update:configuration', {
            ...configuration,
            messageBody: $event,
          })
        "
      />
    </div>
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomTextarea from "@/sharedComponents/CustomTextarea";

export default {
  name: "ActionSendSmsConfiguration",
  components: {
    CustomDropdown,
    CustomTextarea,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      vendors: this.$store.getters['user/smsAutomationVendors'],
    };
  },
  computed: {
    messageBodyHint() {
      let hint = 'Message Character Count: ' + this.configuration.messageBody.length + '/160';

      if (this.configuration.messageBody.length > 160) {
        hint += '. This message will be sent in multiple parts.';
      }

      return hint;
    },
  },
  watch: {
    'configuration.messageBody': {
      handler(val) {
        this.$emit('update:configuration', {
          ...this.configuration,
          name: val.length > 15 ? (val.substr(0, 12) + '...') : val,
        });
      },
    },
  },
  created() {
    if (this.vendors.length === 1) {
      this.$emit('update:configuration', {
        ...this.configuration,
        vendor: this.vendors[0].id,
      });
    }
  },
};
</script>

<style lang="scss">
</style>
