var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.has_preview)?_c('v-group',{attrs:{"config":{ 
    offsetY: _vm.readOnly ? -30 : -7,
    offsetX: -52,
    width: 200
  }}},[_c('v-group',{attrs:{"config":{
      width: 100,
    }},on:{"mouseenter":_vm.setCursorPointer,"mouseleave":_vm.setCursorDefault,"click":function($event){return _vm.$emit('onPreview')}}},[_c('v-image',{attrs:{"config":_vm.iconConfig}}),_c('v-text',{attrs:{"config":{
        text: 'Preview',
        fontSize: 11,
        offsetY: -1,
        offsetX: -21,
        fontWeight: 200,
        fontFamily: 'Open Sans',
        fill: '#ffffff',
      }}})],1),_c('v-group',{attrs:{"config":{
      offsetY: -29,
      width: 100,
    }},on:{"mouseenter":_vm.setCursorPointer,"mouseleave":_vm.setCursorDefault,"click":function($event){return _vm.$emit('onConfig')}}},[_c('v-image',{attrs:{"config":_vm.pencilIconConfig}}),_c('v-text',{attrs:{"config":{
        text: _vm.configuredLabel,
        fontSize: 11,
        offsetY: -1,
        offsetX: -21,
        fontWeight: 200,
        fontFamily: 'Open Sans',
        fill: '#ffffff',
      }}})],1),(!_vm.readOnly)?_c('v-group',{attrs:{"config":{
      offsetY: -58,
      width: 100,
    }},on:{"mouseenter":_vm.setCursorPointer,"mouseleave":_vm.setCursorDefault,"click":function($event){return _vm.$emit('onDelete')}}},[_c('v-image',{attrs:{"config":_vm.trashIconConfig}}),_c('v-text',{attrs:{"config":{
        text: 'Delete',
        fontSize: 11,
        offsetY: -1,
        offsetX: -21,
        fontWeight: 200,
        fontFamily: 'Open Sans',
        fill: '#ffffff',
      }}})],1):_vm._e()],1):_c('v-group',{attrs:{"config":{ 
    offsetY: _vm.readOnly ? -50 : -25,
    offsetX: -52,
    width: 200
  }}},[_c('v-group',{attrs:{"config":{
      offsetY: 0,
      width: 100,
    }},on:{"mouseenter":_vm.setCursorPointer,"mouseleave":_vm.setCursorDefault,"click":function($event){return _vm.$emit('onConfig')}}},[_c('v-image',{attrs:{"config":_vm.pencilIconConfig}}),_c('v-text',{attrs:{"config":{
        text: _vm.configuredLabel,
        fontSize: 11,
        offsetY: -1,
        offsetX: -21,
        fontWeight: 200,
        fontFamily: 'Open Sans',
        fill: '#ffffff',
      }}})],1),(!_vm.readOnly)?_c('v-group',{attrs:{"config":{
      offsetY: -29,
      width: 100,
    }},on:{"mouseenter":_vm.setCursorPointer,"mouseleave":_vm.setCursorDefault,"click":function($event){return _vm.$emit('onDelete')}}},[_c('v-image',{attrs:{"config":_vm.trashIconConfig}}),_c('v-text',{attrs:{"config":{
        text: 'Delete',
        fontSize: 11,
        offsetY: -1,
        offsetX: -21,
        fontWeight: 200,
        fontFamily: 'Open Sans',
        fill: '#ffffff',
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }