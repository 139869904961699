<template>
  <div data-app class="custom-date-picker">
    <div :class="headerClass || 'field__subheader d-flex'">
      <span
        v-if="required"
        class="field__subheader--required"
      >
        *
      </span>
      {{ header }}
      <slot name="icon" />
    </div>
    <v-menu
      :disabled="readonly"
      :value="showDatePicker || showTimePicker"
      :close-on-content-click="false"
      :close-on-click="true"
      transition="scale-transition"
      class="date-picker"
      max-width="290px"
      min-width="290px"
      @input="(payload) => onInputMenu(payload)"
    >
      <template #activator="{}">
        <v-row>
          <v-col cols="7">
            <v-text-field
              :value="dateAndTime.substring(0,14)"
              class="mt-4"
              dense
              readonly
              outlined
              height="30px"
              :placeholder="placeholder"
              @input="$emit('date-input', $event)"
              @click="showDatePicker = true"
              @click-on-append-icon="showDatePicker = true"
            >
              <template #append>
                <v-btn
                  class="date-picker__button"
                  small
                  icon
                  @click="showDatePicker = true"
                >
                  <v-icon size="18" color="#66788e">
                    mdi-calendar-range
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <vue-timepicker
              v-model="timeData"
              class="mt-4"
              format="hh:mm A"
              :minute-interval="5"
              input-class="v-text-field"
              placeholder="Start Time"
              hide-clear-button
              @input="$emit('time-input', $event);"
            />
          </v-col>
        </v-row>
      </template>
      <v-date-picker
        v-if="showDatePicker"
        :value="date || (datetime ? datetime.substring(0,10) : nowDate)"
        :first-day-of-week="7"
        locale="en"
        no-title
        @input="
          $emit('date-input', $event);
          showDatePicker = false;
        "
      />
    </v-menu>
  </div>
</template>

<script>
import 'vue2-timepicker/dist/VueTimepicker.css'
import datesMixin from "@/utils/datesMixin.js";
import VueTimepicker from 'vue2-timepicker'
import dayjs from "dayjs";

export default {
  name: "CustomDateAndTimePicker",
  components: {VueTimepicker},
  mixins: [datesMixin],
  props: {
    header: {
      default: () => {},
      type: String,
    },
    readonly: {
      default: () => {},
      type: Boolean,
    },
    headerClass: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => "Choose a date",
      type: String,
    },
    time: {
      default: () => {},
    },
    date: {
      default: () => {},
    },
    datetime: {
      default: () => {},
    },
    outerDateAndTime: {
      default: () => {},
    },
    allowed_minutes: {
      default: () => [],
    },
    required: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      showDatePicker: false,
      showTimePicker: false,
      timeData: '12:00 AM',
      nowDate: dayjs(new Date()).format('YYYY-MM-DD'),
    };
  },
  computed: {
    dateTime() {
      if (!this.date && !this.datetime) {
        return '';
      }

      if (!this.date && !this.datetime) {
        return this.time;
      }

      if (!this.time && !this.datetime) {
        return this.getDateTime(this.date);
      }

      if (this.time && this.date) {
        return this.getDateTime(this.date + ' ' + this.time);
      }

      return this.getDateTime(this.datetime);
    },
    dateAndTime() {
      if (!this.date && !this.datetime) {
        return '';
      }

      if (!this.date && !this.datetime) {
        return this.time;
      }

      if (!this.time && !this.datetime) {
        return this.getDateWithDayName(this.date);
      }

      if (this.time && this.date) {
        return this.getDateWithDayNameAndTime(this.date + ' ' + this.time);
      }

      return this.getDateWithDayNameAndTime(this.datetime);
    },
  },
  watch: {
    time: {
      deep: true,
      handler() {
        if (this.datetime || this.time) {
          this.timeData = this.dateTime;
        }
      },
    },
    datetime: {
      deep: true,
      handler() {
        if (this.datetime) {
          this.timeData = this.dateTime;
        }
      },
    },
  },
  mounted() {
    if (this.datetime) {
      this.timeData = this.dateTime;
    }
  },
  methods: {
    onInputMenu(value) {
      if (value === false || value === undefined) {
        this.showDatePicker = false;
        this.showTimePicker = false;
      }
    },
  },
};
</script>

<style lang="scss">
.date-picker {
  &__button {
    margin-top: -2px;
  }
}

.date-picker-text-field {
  .v-input__slot {
    width: 150px;
  }
}
.custom-date-picker {
  .v-text-field {
    fieldset {
      border-radius: 4px !important;
      border: solid 1px #dee7ee !important;
      background-color: #ffffff !important;
    }
    input {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .v-input__slot {
      max-height: 44px !important;
      min-height: 44px !important;
      height: 44px !important;
    }
    .v-input__append-inner {
      margin-top: 10px !important;
      margin-right: -4px;
    }
  }

  .time-picker {
    .v-text-field {
        border-radius: 4px !important;
        border: solid 1px #dee7ee !important;
        background-color: #ffffff !important;
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        max-height: 44px !important;
        min-height: 44px !important;
        height: 44px !important;
        margin-top: 0 !important;
      }
    }
  .vue__time-picker .dropdown ul li:not([disabled]).active {
    background: #2b84eb;
  }

  /* When using "append-to-body" */
  .vue__time-picker-dropdown ul li:not([disabled]).active {
    background: #2b84eb;
  }
}
</style>
