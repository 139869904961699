<template>
  <v-group
    ref="node"
    :config="nodeConfig"
    @dragstart="onDragStart"
    @dragmove="onDragging"
    @dragend="onDragEnd"
    @click="onSelect"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <v-rect
      :config="parentConfig"
      @dblclick="openConfiguration"
    />
    <v-image
      :config="removeIconConfig"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="onRemoveClick"
    />
    <v-group
      v-if="!isHovered"
      :config="detailsConfig"
    >
      <v-text :config="nodeNameConfig" />
      <v-text :config="dbNodeConfig" />
      <div v-if="!isShowCounts">
        <v-image
          v-if="nodeImageUrl"
          :config="nodeIconConfig"
        />
        <v-text :config="nodeLabelConfig" />
      </div>
      <div v-else>
        <v-text :config="nodeCountEnteredLabelConfig" />
        <v-text :config="nodeCountProcessingLabelConfig" />
        <v-text :config="nodeCountCompletedLabelConfig" />
        <v-text :config="nodeCountEndedLabelConfig" />
      </div>
    </v-group>
    <NodeHoverConfiguration 
      v-else 
      :configured="!!node.config.name"
      @onPreview="togglePreview" 
      @onConfig="openConfiguration" 
      @onDelete="onRemoveClick" 
    />
    <v-group v-if="!readOnly && !isShowCounts">
      <v-group
        @mouseenter="onDynamicTransitionBarHover('yes')"
        @mouseleave="onDynamicTransitionBarLeave('yes')"
      >
        <v-rect ref="transitionBar" :config="dynamicTransitionBarConfig('yes')" />
        <v-text :config="dynamicTransitionTextConfig('yes')" />
      </v-group>
      <v-group
        @mouseenter="onDynamicTransitionBarHover('no')"
        @mouseleave="onDynamicTransitionBarLeave('no')"
      >
        <v-rect ref="transitionBar" :config="dynamicTransitionBarConfig('no')" />
        <v-text :config="dynamicTransitionTextConfig('no')" />
      </v-group>
    </v-group>
    
    <NodeStatistics :node="node" />
  </v-group>
</template>

<script>
import CampaignNodeMixin from "@/mixins/campaign-node-mixin";
import CampaignMixin from "@/mixins/campaign-mixin";
import MultipleTransitionsMixin from "@/mixins/multiple-transitions-mixin";
import NodeStatistics from "./NodeStatistics";
import NodeHoverConfiguration from "@/views/Campaigns/components/automation/NodeHoverConfiguration";

export default {
  name: "ConditionIsInSegmentNode",
  components: {NodeHoverConfiguration, NodeStatistics},
  mixins: [CampaignNodeMixin, CampaignMixin, MultipleTransitionsMixin],
  data() {
    return {
      transitionHoveredBorderColor: '#fa9982',
      transitionTextColor: '#cb2600',
    };
  },
  computed: {
    nodeImageUrl() {
      if (!this.node?.config?.segmentId) {
        return null;
      }

      return this.segments.find(segment => segment.id === this.node.config.segmentId)?.imageUrl;
    },
    primaryIconConfig() {
      return {
        fill: '#FFF',
        fontFamily: 'FontAwesome',
        fontSize: 35,
        listening: false,
        name: 'primaryIcon',
        offsetX: 93,
        offsetY: 20,
        text: "\uF0c0",
        transformsEnabled: 'position',
        visible: true,
      };
    },
    transitionEvents() {
      return {
        'yes': {
          transitionBarConfig: {
            offsetX: !this.isSelected ? 0: -2,
            width: this.isSelected ? 94 : 96,
            cornerRadius: [0, 0, 0, 3]
          },
          transitionTextConfig: {
            offsetX: !this.isSelected ? -1 : -3,
            text: 'Yes',
            width: this.isSelected ? 97 : 92,
          },
        },
        'no': {
          transitionBarConfig: {
            offsetX: !this.isSelected ? -97 : -97,
            width: this.isSelected ?  93: 94,
            cornerRadius: [0, 0, 3, 0]
          },
          transitionTextConfig: {
            offsetX: !this.isSelected ? -98 : -89,
            text: 'No',
            width: this.isSelected ? 99 : 92,
          },
        },
      };
    },
  },
  mounted() {
    if (this.nodeImageUrl) {
      this.nodeIconObj.src = this.nodeImageUrl;
    }
  },
}
</script>
