<template>
  <v-group
    ref="node"
    :config="nodeConfig"
    @dragstart="onDragStart"
    @dragmove="onDragging"
    @dragend="onDragEnd"
    @click="onSelect"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <v-rect
      :config="parentConfig"
      @dblclick="openConfiguration"
    />
    <v-image
      :config="removeIconConfig"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="onRemoveClick"
    />
    <v-group
      v-if="!isHovered"
      :config="detailsConfig"
    >
      <v-text :config="nodeNameConfig" />
      <v-text :config="dbNodeConfig" />
      <div v-if="!isShowCounts">
        <v-text :config="nodeLabelConfig" />
      </div>
      <div v-else>
        <v-text :config="nodeCountEnteredLabelConfig" />
        <v-text :config="nodeCountProcessingLabelConfig" />
        <v-text :config="nodeCountCompletedLabelConfig" />
        <v-text :config="nodeCountEndedLabelConfig" />
      </div>
    </v-group>
    <NodeHoverConfiguration
      v-else
      :configured="!!node.config.name"
      @onPreview="togglePreview"
      @onConfig="openConfiguration"
      @onDelete="onRemoveClick"
    />
    <v-group
      v-if="!readOnly && !isShowCounts"
      @mouseenter="onTransitionBarHover"
      @mouseleave="onTransitionBarLeave"
    >
      <v-rect ref="transitionBar" :config="transitionBarConfig" />
      <v-text :config="transitionTextConfig" />
    </v-group>
    <NodeStatistics :node="node" />
  </v-group>
</template>

<script>
    import CampaignNodeMixin from "@/mixins/campaign-node-mixin";
    import CampaignMixin from "@/mixins/campaign-mixin";
    import MultipleTransitionsMixin from "@/mixins/multiple-transitions-mixin";
    import NodeStatistics from "./NodeStatistics";
    import NodeHoverConfiguration from "@/views/Campaigns/components/automation/NodeHoverConfiguration";

    export default {
        name: "ConditionCheckEmailStatusNode",
        components: {NodeHoverConfiguration, NodeStatistics},
        mixins: [CampaignNodeMixin, MultipleTransitionsMixin, CampaignMixin],
        data() {
            return {
                nodeFontColor: '#414d58',
                transitionHoveredBorderColor: '#fce589',
                transitionTextColor: '#414d58',
            };
        },
        computed: {
            /*nodeLabelConfig() {

                // Set defaults
                let label = this.node.config.label || 'Double Click to Configure';
                let fontSize = 18;
                let offsetY = 28;

                // Create a fake text element to determine if the label will get cut off due to the constraints
                const text = new Konva.Text({
                    align: 'center',
                    fontSize: fontSize,
                    fontStyle: 'bold',
                    height: 40,
                    lineHeight: 1.1,
                    text: label,
                    width: this.node.config.label ? 150 : 100,
                });

                // If the textArr has more than one item, it means the label was too long and got wrapped
                if(text.textArr.length > 1)
                {
                    // Make the test smaller
                    fontSize = 14;
                    text.fontSize(fontSize);

                    // If the text still goes on two lines after making the font smaller, it'll have to go on two lines
                    // Adjust the y offset so the text is centered
                    if(text.textArr.length > 1)
                    {
                        offsetY = 35;
                        text.offsetY(offsetY);
                    }

                    // Get the text that is actually visible (after being cut off)
                    const textActuallyShown = text.textArr.map(x => x.text).join(' ');

                    const hasMissingText = textActuallyShown !== label;
                    if(hasMissingText)
                    {
                        // If there's any missing text then manually add the ellipses
                        // If we use the configuration option instead, the text will get cut off after the first line
                        let charsToRemove = 0;
                        while(text.textArr.map(x => x.text).join(' ').substr(-3) !== '...')
                        {
                            // Keep removing characters from the label until all 3 dots are visible
                            label = textActuallyShown.substr(0, textActuallyShown.length - charsToRemove++) + '...';
                            text.setText(label);
                        }
                    }
                }

                return {
                    align: 'center',
                    ellipsis: true,
                    fill: this.nodeFontColor,
                    fontSize,
                    fontStyle: 'bold',
                    height: 40,
                    lineHeight: 1.1,
                    listening: false,
                    name: 'nodeLabel',
                    offsetX: this.node.config.label ? 45 : 20,
                    offsetY,
                    text: label,
                    transformsEnabled: 'position',
                    width: this.node.config.label ? 150 : 100,
                };
            },*/

            previewImageSource() {
                return '/img/demo-creative-thumb.jpg';
            },

            primaryIconConfig() {
                return {
                    fill: '#FFF',
                    fontFamily: 'FontAwesome',
                    fontSize: 30,
                    listening: false,
                    name: 'primaryIcon',
                    offsetX: 93,
                    offsetY: 37,
                    text: "\uF0e0",
                    visible: true,
                };
            },

        },
    }
</script>
