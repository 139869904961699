<template>
  <v-row class="d-flex align-center justify-space-between pb-0">
    <v-col
      style="max-width: 380px"
      cols="12"
      sm="8"
      lg="6"
      xl="4"
      class="d-flex align-center justify-start pr-2"
    >
      <SearchField
        v-model="searchString"
        :disable_search_field="disable_search_field"
        :disable_search_on_enter="disable_search_on_enter"
        :placeholder="searchFieldPlaceholder"
        @search="$emit('search', searchString)"
      />
    </v-col>
    <v-col cols="auto" class="flex-row d-flex align-center">
      <slot />
    </v-col>
    <v-spacer />
    <v-col
      v-if="countTotal > pageSizes[0]"
      cols="auto"
      class="d-flex flex-nowrap align-center justify-end flex-grow-1 pl-2"
    >
      <pagination-buttons
        :current-page-number="currentPageNumber"
        :current-page-size="currentPageSize"
        :count-total="countTotal"
        :page-sizes="pageSizes"
        @next-page="$emit('next-page')"
        @prev-page="$emit('prev-page')"
        @change-page-size="(item) => $emit('change-page-size', item)"
      />
    </v-col>
  </v-row>
</template>

<script>
import SearchField from "@/sharedComponents/SearchFieldWithButton";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "SearchFieldRowWithPagination",
  components: {
    SearchField,
    PaginationButtons
  },
  props: {
    noPagination: {
      default: () => false,
      type: Boolean,
    },
    disable_search_field: {
      default: () => false,
      type: Boolean,
    },
    disable_search_on_enter: {
      default: () => false,
      type: Boolean,
    },
    searchFieldPlaceholder: {
      default: () => {},
      type: String,
    },
    currentPageNumber: {
      default: () => 1,
      type: Number,
    },
    currentPageSize: {
      default: () => {},
      type: Number,
    },
    countTotal: {
      default: () => {},
      type: Number,
    },
    pageSizes: {
      default: () => [],
      type: Array,
    },
    initSearchString: {
      default: '',
      type: String
    },
  },
  data() {
    return {
      searchString: "",
    };
  },
  watch: {
    initSearchString: function (val) {
      this.searchString = val;
    }
  },
  created() {
    this.searchString = this.initSearchString;
  },
};
</script>
