import Campaigns from "../store/api/Campaigns";
import Konva from "konva";
import default_icons from "@/assets/icons/automated/default_icons";
export default {
  props: {
    node: {
      type: Object,
      required: true
    },
    segments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const nodeTemplate = Campaigns.getNodeTemplateForType(
      this.node.type,
      this.node.node_type
    );
    this.imageObj = new Image(10, 10);
    this.imageObj.src = this.node.ui.icons[1] || this.node.ui.icons[0];
    return {
      defaultTransitionHoverEvent: true,
      headImg: new Image(100, 100),
      testImg: new Image(100, 100),
      trashImg: new Image(100, 100),
      pencilImg: new Image(100, 100),
      card_width: 182,
      card_height: 120,
      card_active_border_width: 2,
      card_footer_position_x: 10,
      card_footer_position_y: 10,
      card_icon_position_x: 0,
      card_icon_position_y: 0,
      // Whether or not the node can be connected to with a transition
      canBeConnectedTo: true,
      // Is the node currently being hovered over?
      isHovered: false,
      // Is a transition bar currently being hovered over?
      transitionHovered: null,
      // The color of the main body of the node - determined by the node type
      nodeColor: nodeTemplate.ui.color,
      // The color used for the text and icons on the node
      nodeFontColor: "#FFF",
      // The name of the type of node
      nodeName: nodeTemplate.ui.label,
      // The color of the transition bar
      transitionBarColor: "",
      // The Konva/UI element that is instantiated in the mounted() lifecycle and used for Konva specific functionality
      uiElement: null,

      dbNodeConfig: {
        align: "center",
        fill: "#FFF",
        fontSize: 13,
        height: "auto",
        lineHeight: 1.3,
        listening: false,
        offsetX: 70,
        offsetY: 70,
        text: "",
        visible: true
      },

      // The details group which contains things such as the node name, label, icons and db node id
      detailsConfig: {
        name: "detailGroup",
        visible: !this.isHovered
      },

      nodeIconObj: new Image(25, 25),
    };
  },
  computed: {
    nodeImageUrl() {
      return null;
    },
    iconConfig: {
      get() {
        return {
          image: this.testImg,
          width: 12,
          height: 12
        };
      }
    },
    pencilIconConfig: {
      get() {
        return {
          image: this.pencilImg,
          width: 12,
          height: 12
        };
      }
    },
    trashIconConfig: {
      get() {
        return {
          image: this.trashImg,
          width: 12,
          height: 12
        };
      }
    },
    isPreviewVisible() {
      return this.node.ui.isPreviewVisible || false;
    },
    isSelected() {
      const selectedNode = this.$store.state.automation.nodeBeingSelected;
      return selectedNode ? selectedNode.nuid === this.node.nuid : false;
    },

    // The config of the main/full group node
    nodeConfig() {
      return {
        draggable: true,
        name: "fullNode-" + this.node.nuid,
        scaleX: this.node.isNew ? 0 : 1,
        scaleY: this.node.isNew ? 0 : 1,
        type: this.node.node_type,
        x: this.node.position.x,
        y: this.node.position.y
      };
    },

    // The config for the node icon set by the user on the specific node
    nodeIconConfig() {
      return {
        name: "nodeIcon",
        offsetY: -45,
        offsetX: -11,
        width: 25,
        height: 25,
        image: this.nodeIconObj,
        transformsEnabled: "position",
        visible: !this.isHovered
      }
    },

    // The config for the node label set by the user on the specific node
    nodeLabelConfig() {
      // Set defaults
      let label = this.node.config.name || "Unconfigured";
      let fontSize = 13;
      let offsetY = 12;

      // Create a fake text element to determine if the label will get cut off due to the constraints
      const text = new Konva.Text({
        align: "center",
        fontSize: fontSize,
        fontStyle: "bold",
        height: 40,
        lineHeight: 1.1,
        text: label,
        width: this.node.config.label ? 150 : 100
      });

      // If the textArr has more than one item, it means the label was too long and got wrapped
      if (text.textArr.length > 1) {
        // Make the test smaller
        fontSize = 14;
        text.fontSize(fontSize);

        // If the text still goes on two lines after making the font smaller, it'll have to go on two lines
        // Adjust the y offset so the text is centered
        if (text.textArr.length > 1) {
          offsetY = 17;
          text.offsetY(offsetY);
        }

        // Get the text that is actually visible (after being cut off)
        const textActuallyShown = text.textArr.map(x => x.text).join(" ");

        const hasMissingText = textActuallyShown !== label;
        if (hasMissingText) {
          // If there's any missing text then manually add the ellipses
          // If we use the configuration option instead, the text will get cut off after the first line
          let charsToRemove = 0;
          while (
            text.textArr
              .map(x => x.text)
              .join(" ")
              .substr(-3) !== "..."
          ) {
            // Keep removing characters from the label until all 3 dots are visible
            label =
              textActuallyShown.substr(
                0,
                textActuallyShown.length - charsToRemove++
              ) + "...";
            text.setText(label);
          }
        }
      }

      return {
        fill: "#ffffff",
        opacity: label === "Unconfigured" ? 0.8 : 1,
        padding: 5,
        align: "center",
        ellipsis: true,
        fontFamily: "Open Sans",
        fontSize,
        fontStyle: "bold",
        height: 40,
        verticalAlign: "center",
        lineHeight: 1.1,
        listening: false,
        name: "nodeLabel",
        offsetY: -45,
        offsetX: this.nodeImageUrl ? -30 : 0,
        text: label,
        transformsEnabled: "position",
        width: this.nodeImageUrl ? 160 : 176
      };
    },

    // The config for the node label set by the user on the specific node
    nodeCountEnteredLabelConfig() {
      const label = `Entered: ${this.node.entered ? this.node.entered.toLocaleString('en-US') : 0}`;

      return {
        fill: "#ffffff",
        align: "left",
        fontFamily: "Open Sans",
        fontSize: 10,
        fontStyle: "bold",
        height: 50,
        verticalAlign: "center",
        lineHeight: 1.30,
        listening: false,
        name: "nodeCountEnteredLabel",
        offsetX: -30,
        offsetY: -40,
        text: label,
        transformsEnabled: "position",
        width: 176
      };
    },
    // The config for the node label set by the user on the specific node
    nodeCountProcessingLabelConfig() {
      const label = `Processing: ${this.node.processing ? this.node.processing.toLocaleString('en-US') : 0}`

      return {
        fill: "#ffffff",
        align: "left",
        fontFamily: "Open Sans",
        fontSize: 10,
        fontStyle: "bold",
        height: 50,
        verticalAlign: "center",
        lineHeight: 1.30,
        listening: false,
        name: "nodeCountProcessingLabel",
        offsetX: -30,
        offsetY: -60,
        text: label,
        transformsEnabled: "position",
        width: 176
      };
    },
    nodeCountCompletedLabelConfig() {
      const label = `Completed: ${this.node.completed ? this.node.completed.toLocaleString('en-US') : 0}`

      return {
        fill: "#ffffff",
        align: "left",
        fontFamily: "Open Sans",
        fontSize: 10,
        fontStyle: "bold",
        height: 50,
        verticalAlign: "center",
        lineHeight: 1.30,
        listening: false,
        name: "nodeCountCompletedLabel",
        offsetX: -30,
        offsetY: -80,
        text: label,
        transformsEnabled: "position",
        width: 176
      };
    },
    nodeCountEndedLabelConfig() {
      const label = `Ended: ${this.node.failed ? this.node.failed.toLocaleString('en-US') : 0}`

      return {
        fill: "#ffffff",
        align: "left",
        fontFamily: "Open Sans",
        fontSize: 10,
        fontStyle: "bold",
        height: 50,
        verticalAlign: "center",
        lineHeight: 1.30,
        listening: false,
        name: "nodeCountEndedLabel",
        offsetX: -30,
        offsetY: -100,
        text: label,
        transformsEnabled: "position",
        width: 176
      };
    },

    // The config for the name of the node
    nodeNameConfig() {
      return {
        fontSize: 14,
        fontWeight: 600,
        fontFamily: "Open Sans",
        offsetY: -5,
        offsetX: -6,
        fill: "#ffffff",
        padding: 5,
        align: "center",
        height: "auto",
        lineHeight: 1.3,
        listening: false,
        name: "nodeName",
        text: this.nodeName,
        transformsEnabled: "position",
        visible: true,
        width: 192
      };
    },

    // The config for the main parent body/rectangle
    parentConfig() {
      return {
        fill: this.nodeColor,
        height: 120,
        cornerRadius: 3,
        name: "parent",
        opacity: this.isHovered ? 0.8 : 1,
        offsetX: 0,
        offsetY: 0,
        shadowBlur: 8,
        stroke: !this.isSelected ? "" : "#042039",
        strokeWidth: 3,
        transformsEnabled: "position",
        width: 192
      };
    },
    removeIconConfig() {
      return {
        name: "removeIcon",
        offsetY: -11,
        offsetX: -11,
        width: 14,
        height: 14,
        image: this.imageObj,
        transformsEnabled: "position",
        visible: !this.isHovered
      };
    },

    transitionBarConfig() {
      return {
        fill: this.transitionBarColor,
        height: 33,
        listening: !this.$store.state.automation.transitionBeingCreated,
        name: "transitionBar",
        offsetX: !this.isSelected ? 0 : -2,
        offsetY: !this.isSelected ? -87 : -85,
        opacity: this.isHovered ? 0.7 : 1,
        transformsEnabled: "position",
        width: !this.isSelected ? 192 : 188,
        cornerRadius: [0, 0, 3, 3]
      };
    },

    transitionTextConfig() {
      return {
        align: "center",
        fill: "#FFF",
        fontSize: 14,
        name: "transitionText",
        offsetX: !this.isSelected ? 0 : -2,
        offsetY: -97,
        text: "Drag to Connect",
        transformsEnabled: "position",
        width: !this.isSelected ? 192 : 188,
        wrap: "none"
      };
    },
    isShowCounts() {
      return this.$store.state.automation.isShowCounts
    },
  },
  methods: {
    //hitGraphEnabled method is deprecated. Please use layer.listening() instead.
    // Triggered when the node starts being dragged in the canvas
    onDragStart(e) {
      if (this.transitionHovered) {
        this.onStartCreatingTransition(e);
      } else {
        this.$store.dispatch("automation/startDraggingNode", this.node.nuid);
        this.uiElement
          .getStage()
          .findOne(".mainLayer")
          .listening(false);
        //.hitGraphEnabled(false);
        this.uiElement
          .getStage()
          .findOne(".actionLayer")
          .listening(false);
        //.hitGraphEnabled(false);
      }
    },
    // Triggered multiple times as the node continues to be dragged in the canvas
    onDragging(e) {
      if (!this.$store.state.automation.transitionBeingCreated) {
        this.$store.dispatch("automation/draggedNodeToPosition", {
          x: e.target.getX(),
          y: e.target.getY()
        });
      }
    },
    // Triggered when the node stops being dragged in the canvas
    onDragEnd(e) {
      if (
        e.target &&
        e.target.draggable() &&
        !this.$store.state.automation.transitionBeingCreated
      ) {
        this.$store.dispatch("automation/stopDraggingNode");
        this.uiElement
          .getStage()
          .findOne(".mainLayer")
          .listening(true);
        this.uiElement
          .getStage()
          .findOne(".actionLayer")
          .listening(true);
      }
    },
    // Triggered when the user starts hovering over the node
    onMouseEnter(e) {
      if (
        this.canBeConnectedTo &&
        !!this.$store.state.automation.transitionBeingCreated
      ) {
        this.$store.dispatch(
          "automation/setTargetNodeForTransition",
          this.node.nuid
        );
      } else {
        this.isHovered = true;

        if (!this.node.isNew && e.currentTarget.parent.attrs.name === 'mainLayer') {
          this.$store.dispatch("automation/hoverNode", this.node.nuid);
        }
      }
    },
    // Triggered when the user stops hovering over the node
    async onMouseLeave() {
      if (
        this.canBeConnectedTo &&
        !!this.$store.state.automation.transitionBeingCreated
      ) {
        await this.$store.dispatch(
          "automation/setTargetNodeForTransition",
          null
        );
      } else {
        this.isHovered = false;

        // Use timeout here to try and fix most normal cases for PEC-1327
        // Basically if the user moves the mouse through a node really quickly it can cause
        // The node to get stuck in a "hover" effect without actually being hovered
        // The reason is because two mouse enter events are triggered when a user hovers a node
        // One for Main Layer and another for Action Layer when the node changes layers
        // If the user moves too quickly, the mouse leave event happens before one of
        // the two mouse enter events which causes the mouse enter to leave the node stuck in
        // that state. To get around most normal cases, we set this timeout to give those
        // mouse enter events a chance to run before this one
        setTimeout(() => {
          this.$store.dispatch("automation/stopHoveringNode");
        }, 150);
      }
    },
    // Triggered when the user clicks on the remove icon
    onRemoveClick() {
      this.$store.dispatch("automation/deleteNode", this.node.nuid);
    },
    onSelect() {
      this.$store.dispatch("automation/selectNode", this.node.nuid);
    },
    // Triggered when the application wants to being creating a transition from the current node
    onStartCreatingTransition() {
      this.uiElement.draggable(false);
      let transition_event = "";
      if (typeof this.transitionHovered !== "boolean") {
        transition_event = this.transitionHovered;
      } else if (typeof this.transitionEvents === "object") {
        transition_event = Object.keys(this.transitionEvents)[0];
      } else {
        transition_event = this.isHovered;
      }

      this.$store.dispatch("automation/startTransitionFromNode", {
        nuid: this.node.nuid,
        event: transition_event
      });
    },
    // Triggered when a transition bar on the node is hovered over
    onTransitionBarHover() {
      this.transitionHovered = this.defaultTransitionHoverEvent;
      this.setCursorPointer();
    },
    // Triggered when a transition bar on the node is not hovered over any more
    onTransitionBarLeave() {
      this.transitionHovered = null;
      this.setCursorDefault();
    },
    openConfiguration() {
      this.$store.dispatch("automation/startConfiguringNode", this.node.nuid);
    },
    // Sets the cursor to the pointer style
    setCursorPointer() {
      this.uiElement.getStage().container().style.cursor = "pointer";
    },
    // Sets the cursor to the default style
    setCursorDefault() {
      this.uiElement.getStage().container().style.cursor = "default";
    },
    togglePreview() {
      this.$store.dispatch(
        "automation/togglePreviewStatusForNode",
        this.node.nuid
      );
    }
  },
  async mounted() {
    if (!this.$refs.node) return;

    // Get the Konva element for future usage
    this.testImg.src = default_icons.Eye;
    this.trashImg.src = default_icons.Trash;
    this.pencilImg.src = default_icons.Pencil;
    this.headImg.src = default_icons.Mail;

    this.uiElement = this.$refs.node.getNode();
    if (this.uiElement.getLayer().getName() === "actionLayer") {
      this.isHovered = true;
    }

    // TODO: Change this to trigger after the node is added to the layer
    // Otherwise, we'll keep getting errors in the console about it
    if (this.node.isNew) {
      // If the node is newly created, then make a small animation to make it bounce in
      const duration = 200;
      this.uiElement.to({
        scaleX: 1.2,
        scaleY: 1.2,
        duration: (duration - 100) / 1000
      });
      setTimeout(() => {
        this.uiElement.to({
          scaleX: 1,
          scaleY: 1,
          duration: duration / 1000,
          onFinish: () => {
            // Set it to not be new any more so it doesn't do the bounce effect
            this.$store.dispatch("automation/updateNode", {
              isNew: false,
              nuid: this.node.nuid
            });
          }
        });
      }, duration - 100);
    }
  }
};
