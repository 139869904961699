<template>
  <div>
    <div class="mb-3">
      <CustomDropdown
        autocomplete
        header="Vendor"
        header-class="notification-menu__header"
        placeholder="Select an Option"
        class="mt--2"
        :value="configuration.vendor"
        :items="vendors"
        :readonly="vendors.length === 1"
        required
        item-value="id"
        item-text="name"
        @change="
          $emit('update:configuration', {
            ...configuration,
            vendor: $event,
          })
        "
      />
    </div>
    <div class="mb-3">
      <CustomDropdown
        autocomplete
        placeholder="Select an Option"
        header="Direct Mail Creative"
        header-class="notification-menu__header"
        class="mt--2"
        :value="configuration.creativeId"
        :items="creatives"
        required
        item-value="id"
        item-text="name"
        @change="
          $emit('update:configuration', {
            ...configuration,
            creativeId: $event,
          })
        "
      />
    </div>
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "ActionSendDirectMailConfiguration",
  components: {
    CustomDropdown,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      creatives: [],
      vendors: this.$store.getters['user/directMailAutomationVendors'],
    };
  },
  watch: {
    'configuration.creativeId': {
      handler(val) {
        const name = this.creatives.find((x) => x.id === val)?.name;
        if (name) {
          this.$emit('update:configuration', {
            ...this.configuration,
            name,
          });
        }
      },
    },
  },
  created() {
    this.loadCreatives();

    if (this.vendors.length === 1) {
      this.$emit('update:configuration', {
        ...this.configuration,
        vendor: this.vendors[0].id,
      });
    }
  },
  methods: {
    async loadCreatives() {
      this.creatives = (
        await this.$rest.directmails.get_collection({
          sort: ['createdAt:desc'],
          isArchived: this.campaign.status === "draft" || this.campaign.status === "scheduled"
            ? false
            : null,
          ignorePagination: true,
        })
      ).data.items.map(creative => {
        return {
          id: creative.id,
          name: creative.id + ' - ' + creative.name,
        };
      });
    },
  },
};
</script>

<style lang="scss">
</style>
