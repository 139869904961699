<template>
  <v-container class="pa-0" fluid>
    <v-container v-if="activate_testing" class="pa-0" fluid>
      <v-row class="py-0 pb-2">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            :readonly="isReadonly"
            :value="campaign_config.testType"
            header="What is being tested?"
            placeholder="My subject"
            required
            :items="testTypes"
            header-class="field__header"
            :dropdown-class="in_modal ? 'custom-dropdown mt-9' : ''"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                testType: $event,
              })
            "
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            :readonly="isReadonly"
            :value="campaign_config.type"
            header="How many versions are being tested?"
            placeholder="My subject"
            required
            :items="test_amounts"
            header-class="field__header"
            @change="handleUpdateABCType"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <div class="field__header">
            <span class="field__subheader--required">*</span>
            What is the sample size?

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                The percentage of the segments that will receive one of the versions as part of the split testing.<br>
                This set of contacts will become the test group and will be used to determine which version is the<br>
                winner that will be sent to the rest of the segments. 5% per version is recommended.
              </span>
            </v-tooltip>
          </div>
          <v-slider
            :readonly="isReadonly"
            :value="campaign_config.testSizePercent"
            class="custom-slider mt-4 mx-3 mx-sm-0"
            track-color="#dee7ee"
            required
            track-fill-color="#dee7ee"
            thumb-label="always"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                testSizePercent: $event,
              })
            "
          >
            <template #thumb-label="{ value }">
              {{ value }}%
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            :readonly="isReadonly"
            :value="campaign_config.winnerMetric"
            header="Which metric will decide the winner?"
            header-class="field__header"
            placeholder="Winner Selection Method"
            required
            :items="winner_selection_types"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                winnerMetric: $event,
              })
            "
          >
            <template #icon>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  The metric that will be used to determine the winning version.
                </span>
              </v-tooltip>
            </template>
          </CustomDropdown>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            :readonly="isReadonly"
            :value="campaign_config.analysisPhaseDelay"
            header="When to calculate the winning version?"
            header-class="field__header"
            placeholder="Select Time Frame"
            required
            :items="timeframe_types"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                analysisPhaseDelay: $event,
              })
            "
          >
            <template #icon>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  The amount of time that the system will wait after sending emails to the contacts who are part of<br>
                  the test group and calculating the winning version. This allows time for these contacts to interact<br>
                  with the email before a winner is calculated.
                </span>
              </v-tooltip>
            </template>
          </CustomDropdown>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            :readonly="isReadonly"
            :value="campaign_config.sendWinningEmail"
            header="When should the winning email be sent?"
            header-class="field__header"
            placeholder="Winning Email Time Frame"
            required
            :items="send_email_types"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                sendWinningEmail: $event,
              })
            "
          >
            <template #icon>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  The amount of time that the system will wait to send the winning email after calculating the winning<br>
                  version. This allows you to set a delay between when the tests were sent and when the winning email is sent.
                </span>
              </v-tooltip>
            </template>
          </CustomDropdown>
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-6" />
    </v-container>

    <validation-observer v-if="type === 'simple' || campaign_config.testType === 'content'" ref="textInput">
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0 d-flex">
          <CustomTextInput
            :readonly="isReadonly"
            :value="campaign_config.subject"
            class="flex-grow-1"
            header-class="field__header"
            header="Subject Line"
            placeholder="My subject"
            required
            :maxlength="150"
            @change="handleUpdateSubject"
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            :readonly="isReadonly"
            :value="campaign_config.previewText"
            header="Preview Text"
            placeholder="Preview text"
            header-class="field__header"
            :maxlength="150"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                previewText: $event,
              })
            "
          />
        </v-col>
      </v-row>
    </validation-observer>

    <validation-observer v-else-if="type === 'split-test' && campaign_config.testType !== 'content'" ref="textInput">
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0 d-flex">
          <div
            v-if="
              $vuetify.breakpoint.smAndUp &&
                campaign_config.testType !== 'content' &&
                activate_testing
            "
            class="percentage pt-11 pr-3"
          >
            {{ subjectPercentage }}%
          </div>
          <CustomTextInput
            :readonly="isReadonly"
            :value="campaign_config.tests.A.subject"
            class="flex-grow-1"
            header="Subject Line"
            header-class="field__header"
            placeholder="My subject"
            required
            :maxlength="150"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                tests: {
                  ...campaign_config.tests,
                  A: {
                    ...campaign_config.tests.A,
                    subject: $event,
                  },
                },
              })
            "
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            :readonly="isReadonly"
            :value="campaign_config.tests.A.previewText"
            header="Preview Text"
            header-class="field__header"
            placeholder="Preview text"
            :maxlength="150"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                tests: {
                  ...campaign_config.tests,
                  A: {
                    ...campaign_config.tests.A,
                    previewText: $event,
                  },
                },
              })
            "
          />
        </v-col>
      </v-row>

      <v-row class="py-0 pt-3">
        <v-col cols="12" sm="6" class="py-0 d-flex">
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="percentage pt-11 pr-3"
          >
            {{ subjectPercentage }}%
          </div>
          <CustomTextInput
            :readonly="isReadonly"
            :value="campaign_config.tests.B.subject"
            class="flex-grow-1"
            header="Subject Line"
            header-class="field__header"
            placeholder="My subject"
            required
            :maxlength="150"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                tests: {
                  ...campaign_config.tests,
                  B: {
                    ...campaign_config.tests.B,
                    subject: $event,
                  },
                },
              })
            "
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            :readonly="isReadonly"
            :value="campaign_config.tests.B.previewText"
            header="Preview Text"
            header-class="field__header"
            placeholder="Preview text"
            :maxlength="150"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                tests: {
                  ...campaign_config.tests,
                  B: {
                    ...campaign_config.tests.B,
                    previewText: $event,
                  },
                },
              })
            "
          />
        </v-col>
      </v-row>

      <v-row v-if="campaign_config.type === 'ABC'" class="py-0 pt-3">
        <v-col cols="12" sm="6" class="py-0 d-flex">
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="percentage pt-11 pr-3"
          >
            {{ subjectPercentage }}%
          </div>
          <CustomTextInput
            :readonly="isReadonly"
            :value="campaign_config.tests.C.subject"
            class="flex-grow-1"
            header="Subject Line"
            header-class="field__header"
            placeholder="My subject"
            required
            :maxlength="150"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                tests: {
                  ...campaign_config.tests,
                  C: {
                    ...campaign_config.tests.C,
                    subject: $event,
                  },
                },
              })
            "
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            :readonly="isReadonly"
            :value="campaign_config.tests.C.previewText"
            header="Preview Text"
            header-class="field__header"
            placeholder="Preview text"
            :maxlength="150"
            @change="
              $emit('update:campaign_config', {
                ...campaign_config,
                tests: {
                  ...campaign_config.tests,
                  C: {
                    ...campaign_config.tests.C,
                    previewText: $event,
                  },
                },
              })
            "
          />
        </v-col>
      </v-row>
    </validation-observer>

    <v-divider class="mt-3 mb-4" />
    
    <v-row v-if="type === 'simple' || campaign_config.testType === 'subject'">
      <v-col cols="12">
        <div class="field__header">
          <span class="field__subheader--required">*</span>
          Content
        </div>
        <div v-if="!isReadonly" class="field__subheader pt-2 pb-2">
          Choose from an existing email
        </div>
        <v-btn
          v-if="!isReadonly"
          class="custom-button custom-button--gray px-9"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="choose_existing_dialog = true; creativeToUpdate = null"
        >
          Select Content
        </v-btn>
      </v-col>
      <v-col v-if="selected_existing_campaign && selected_existing_campaign.name" class="d-flex align-center justify-center pt-0" cols="12">
        <img
          :src="selected_existing_campaign.imageFileUrls.medium"
          alt=""
          class="campaign-logo mr-4"
        >
        <div class="campaign-title">
          <div>
            {{ selected_existing_campaign.name }}
          </div>
          <small class="text--disabled" style="font-size: 11px">
            ID: {{ selected_existing_campaign.id }}
          </small>
        </div>
      </v-col>
    </v-row>

    <template v-else-if="type === 'split-test' && campaign_config.testType === 'content'">
      <v-row>
        <v-col cols="12">
          <div class="field__header">
            <span class="field__subheader--required">*</span>
            Content for test A
          </div>
          <div v-if="!isReadonly" class="field__subheader pt-2 pb-2">
            Choose from an existing email
          </div>
          <v-btn
            v-if="!isReadonly"
            class="custom-button custom-button--gray px-9"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="choose_existing_dialog = true; creativeToUpdate = 'creativeTest1'"
          >
            Select Content
          </v-btn>
        </v-col>
        <v-col v-if="selected_contact_type_key1 && selected_contact_type_key1.name" class="d-flex align-center justify-center pt-0" cols="12">
          <img
            :src="selected_contact_type_key1.imageFileUrls.medium"
            alt=""
            class="campaign-logo mr-4"
          >
          <div class="campaign-title">
            {{ selected_contact_type_key1.name }}
          </div>
        </v-col>
      </v-row>
      <v-row v-if="campaign_config.type && campaign_config.testType === 'content'">
        <v-col cols="12">
          <div class="field__header">
            <span class="field__subheader--required">*</span>
            Content for test B
          </div>
          <div v-if="!isReadonly" class="field__subheader pt-2 pb-2">
            Choose from an existing email
          </div>
          <v-btn
            v-if="!isReadonly"
            class="custom-button custom-button--gray px-9"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="choose_existing_dialog = true; creativeToUpdate = 'creativeTest2'"
          >
            Select Content
          </v-btn>
        </v-col>
        <v-col v-if="selected_contact_type_key2 && selected_contact_type_key2.name" class="d-flex align-center justify-center pt-0" cols="12">
          <img
            :src="selected_contact_type_key2.imageFileUrls.medium"
            alt=""
            class="campaign-logo mr-4"
          >
          <div class="campaign-title">
            {{ selected_contact_type_key2.name }}
          </div>
        </v-col>
      </v-row>
      <v-row v-if=" campaign_config.type === 'ABC' && campaign_config.testType === 'content'">
        <v-col cols="12">
          <div class="field__header">
            <span class="field__subheader--required">*</span>
            Content for test C
          </div>
          <div v-if="!isReadonly" class="field__subheader pt-2 pb-2">
            Choose from an existing email
          </div>
          <v-btn
            v-if="!isReadonly"
            class="custom-button custom-button--gray px-9"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="choose_existing_dialog = true; creativeToUpdate = 'creativeTest3'"
          >
            Select Content
          </v-btn>
        </v-col>
        <v-col v-if="selected_contact_type_key3 && selected_contact_type_key3.name" class="d-flex align-center justify-center pt-0" cols="12">
          <img
            :src="selected_contact_type_key3.imageFileUrls.medium"
            alt=""
            class="campaign-logo mr-4"
          >
          <div class="campaign-title">
            {{ selected_contact_type_key3.name }}
          </div>
        </v-col>
      </v-row>
    </template>

    <!-- Select Existing Campaign dialog -->
    <v-dialog v-model="choose_existing_dialog" scrollable max-width="900px">
      <ChooseExistingCampaignDialog
        :show-archived="isShowArchivedEmail"
        @dismiss="choose_existing_dialog = false;"
        @select="handleUpdateCreative"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import ChooseExistingCampaignDialog from "@/views/Campaigns/components/ChooseExistingCampaignDialog";
import { EventBus } from "@/sharedComponents/event-bus";

export default {
  name: "SimpleCampaignDetails",
  components: {
    CustomDropdown,
    CustomTextInput,
    ChooseExistingCampaignDialog,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: () => null,
    },
    campaign: {
      type: Object,
      default() {
        return {}
      }
    },
    selected_contact_type_key1: {
      type: Object,
      default: () => null,
    },
    selected_contact_type_key2: {
      type: Object,
      default: () => null,
    },
    selected_contact_type_key3: {
      type: Object,
      default: () => null,
    },
    selected_existing_campaign: {
      type: Object,
      default: () => {},
    },
    campaign_config: {
      type: Object,
      default: () => {},
    },
    activate_testing: {
      type: Boolean,
      default: () => true,
    },
    in_modal: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      creativeToUpdate: null,
      testTypes: [
        { label: "Subject Line", value: "subject" },
        { label: "Content", value: "content" },
      ],
      test_amounts: [
        { label: "A/B (2 versions)", value: 'AB' },
        { label: "A/B/C (3 versions)", value: 'ABC' },
      ],
      winner_selection_types: [
        { label: "Unique Open Rate", value: "open" },
        { label: "Unique Click Rate", value: "click" },
      ],
      timeframe_types: [
        { label: "Immediately after all test emails are sent", value: 0 },
        { label: "1 day", value: 24 },
        { label: "2 days", value: 48 },
        { label: "3 days", value: 72 },
        { label: "4 days", value: 96 },
        { label: "5 days", value: 120 },
        { label: "6 days", value: 144 },
        { label: "7 days", value: 168 },
      ],
      send_email_types: [
        { label: "Immediately after the winner is calculated", value: 0 },
        { label: "After 1 day", value: 24 },
        { label: "After 2 days", value: 48 },
        { label: "After 3 days", value: 72 },
        { label: "After 4 days", value: 96 },
        { label: "After 5 days", value: 120 },
        { label: "After 6 days", value: 144 },
        { label: "After 7 days", value: 168 },
        { label: "Never send the winning email", value: -1 },
      ],
      dialog1: false,
      dialog2: false,
      dialog3: false,
      choose_existing_dialog: false,
    };
  },
  computed: {
    isShowArchivedEmail() {
      return !(this.campaign?.status == 'scheduled' || this.campaign?.status === 'draft');
    },
    subjectPercentage: {
      get() {
        if (this.campaign_config.testType !== "subject")
          return this.campaign_config.testSizePercent || 15;
        else if (this.campaign_config.type === 'AB')
          return this.campaign_config.testSizePercent
            ? (this.campaign_config.testSizePercent / 2).toFixed(1)
            : 0;
        else if (this.campaign_config.type === 'ABC')
          return this.campaign_config.testSizePercent
            ? (this.campaign_config.testSizePercent / 3).toFixed(1)
            : 0;
        return 0;
      },
    },
  },
  async created() {
    EventBus.$on('dismiss-simple-campaign-popup', this.closeActionDialog);
  },
  beforeDestroy() {
    EventBus.$off('dismiss-simple-campaign-popup', this.closeActionDialog);
  },
  methods: {
    handleUpdateCreative(event) {
      if(!this.creativeToUpdate) this.$emit("update:selected_existing_campaign", event)
      if(this.creativeToUpdate == "creativeTest1") this.$emit("update:selected_contact_type_key1", event)
      if(this.creativeToUpdate == "creativeTest2") this.$emit("update:selected_contact_type_key2", event)
      if(this.creativeToUpdate == "creativeTest3") this.$emit("update:selected_contact_type_key3", event)
    },
    handleUpdateSubject(event) {
      const campaign_config = {
        ...this.campaign_config,
        subject: event,
      }
      if (this.campaign_config.testType === 'content') {
        if (this.campaign_config.type === 'ABC') {
          campaign_config["tests"] = {
            ...this.campaign_config.tests,
            A: {
              ...this.campaign_config.tests.A,
              subject: event,
            },
            B: {
              ...this.campaign_config.tests.B,
              subject: event,
            },
            C: {
              ...this.campaign_config.tests.C,
              subject: event,
            },
          }
        } else {
          campaign_config["tests"] = {
            ...this.campaign_config.tests,
            A: {
              ...this.campaign_config.tests.A,
              subject: event,
            },
            B: {
              ...this.campaign_config.tests.B,
              subject: event,
            },
          }
        }
      }
      this.$emit('update:campaign_config', campaign_config);
    },
    handleUpdateABCType(event) {
      if (event === 'ABC') {
        this.$emit('update:campaign_config', {
          ...this.campaign_config,
          type: event,
          tests: {
            ...this.campaign_config.tests,
            C: {
              ...this.campaign_config.tests.C,
              subject: this.campaign_config.subject,
            },
          },
        });
      } else {
        delete this.campaign_config.tests.C;
        this.$emit('update:campaign_config', {
          ...this.campaign_config,
          type: event,
        })
      }
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },
    async validate() {
      return await this.$refs.textInput.validate();
    },

    async save_campaign() {
      try {
        if (await this.validate()) {
          this.$emit('save-campaign')
        }
      } catch (e) {
        console.error(e);
      }
    },
    openSelectionDialog() {
      this.dialog1 = this.dialog2 = this.dialog3 = true;
    },
    closeSelectionDialog() {
      this.dialog1 = this.dialog2 = this.dialog3 = false;
    },
    closeActionDialog() {
      this.choose_existing_dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.percentage {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #2b84eb;
}
.campaign-logo {
  max-height: 95px;
}
.campaign-title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #241c15;
}

::v-deep {
  .v-slider.v-slider--horizontal.theme--light {
    margin: 0 !important;
    .v-slider__track-container {
      height: 9px;
      div:first-child {
        border-top-right-radius: 4.5px !important;
        border-bottom-right-radius: 4.5px !important;
      }
      div:last-child {
        border-top-left-radius: 4.5px !important;
        border-bottom-left-radius: 4.5px !important;
      }
    }
    .v-slider__thumb-container {
      .v-slider__thumb {
        height: 17px;
        width: 17px;
        left: -8px;
        color: transparent;
        background-color: white !important;
        border-color: #dedddc !important;
        border: 1px solid #dedddc;
        cursor: pointer;
      }
    }
    .v-slider__thumb-label-container {
      .v-slider__thumb-label {
        width: 40px !important;
        height: 25px !important;
        transform: none !important;
        background-color: white !important;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
        border-radius: 3px;
        margin-bottom: -40px;
        margin-left: -19px;

        div {
          transform: none !important;
          color: #2b84eb;
          span {
            color: #2b84eb;
          }
        }
      }
    }
  }
}
</style>
