<template>
  <div class="mb-7">
    <div class="notification-menu__header mb-3">
      Email Message
    </div>
    <div class="d-flex flex-column align-center">
      <div v-if="!!configuration.creativeId" class="mb-3 text-center">
        <img src="/img/demo-creative-thumb.jpg" alt="">
      </div>
      <v-btn
        height="34px"
        class="custom-button custom-button--blue"
        width="160px"
        @click.prevent="isSelectingEmailMessage = true"
      >
        {{
          !!configuration.creativeId
            ? "Change Email Message"
            : "Select Email Message"
        }}
      </v-btn>
    </div>
    <div v-if="selectedEmailMessage && selectedEmailMessage.id" class="notification-menu__header mt-7">
      Content
    </div>
    <div
      v-if="selectedEmailMessage && selectedEmailMessage.id"
      class="d-flex align-center justify-center mt-2 mb-5"
    >
      <v-img
        max-height="95px"
        max-width="95px"
        contain
        class="mr-3"
        :src="selectedEmailMessage.imageFileUrls.small"
      />
      <div class="ml-3">
        <div class="campaigns-table__campaign-header">
          {{ selectedEmailMessage.name }}
        </div>
        <small class="text--disabled" style="font-size: 11px">
          ID: {{ selectedEmailMessage.id }}
        </small>
      </div>
    </div>

    <div class="notification-menu__header mt-5">
      From
    </div>
    <CustomDropdown
      placeholder="Select an Option"
      :value="configuration.campaignSearchType"
      :items="campaignOptions"
      item-value="value"
      item-text="text"
      autocomplete
      @input="onCampaignOptionInput"
    />

    <div v-if="showCampaigns" class="mt-5">
      <div class="notification-menu__header">
        Campaign
      </div>
      <CustomDropdown
        v-if="!isLoadingCampaigns"
        placeholder="Select an Option"
        :value="configuration.campaignId"
        :items="campaigns"
        item-value="id"
        item-text="name"
        autocomplete
        @change="
          $emit('update:configuration', {
            ...configuration,
            campaignId: $event,
          });
        "
      />
      <div v-else class="loading-field-placeholder">
        <div>Loading Segments...</div>
      </div>
    </div>

    <v-dialog v-model="isSelectingEmailMessage" scrollable max-width="720px">
      <ChooseExistingCampaignDialog
        @dismiss="isSelectingEmailMessage = false"
        @select="(data) => onSelectCreative(data)"
      />
    </v-dialog>
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import ChooseExistingCampaignDialog from "@/views/Campaigns/components/ChooseExistingCampaignDialog";

export default {
  name: "ConditionCheckEmailStatusConfiguration",
  components: {
    CustomDropdown,
    ChooseExistingCampaignDialog,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      campaignOptions: [
        { value: 'current', text: "This Campaign" },
        { value: 'any', text: "Any Campaign" },
        { value: 'other', text: "Specific Campaign" },
      ],
      campaigns: [],
      isLoadingCampaigns: false,
      isSelectingEmailMessage: false,
      selectedEmailMessage: null,
    };
  },
  computed: {
    showCampaigns() {
      return this.configuration.campaignSearchType
        ? this.configuration.campaignSearchType === 'other'
        : false;
    },
  },
  watch: {
    'selectedEmailMessage.name': {
      handler() {
        this.updateConfigurationNameOnChange();    
      },
    },
    'configuration.campaignSearchType': {
      handler() {
        this.updateConfigurationNameOnChange();    
      },
    },
    'configuration.campaignId': {
      handler() {
        this.updateConfigurationNameOnChange();    
      },
    },
  },
  async mounted() {
    await this.loadCampaigns();

    if (this.configuration.creativeId) {
      this.selectedEmailMessage = (await this.$rest.creative.email.get_resource(this.configuration.creativeId)).data;
    }

    this.onCampaignOptionInput(this.configuration.campaignSearchType);
  },
  methods: {
    updateConfigurationNameOnChange() {
      const emailName = this.selectedEmailMessage?.name;
      if(!this.selectedEmailMessage || !emailName) return
      
      const currentName = this.configuration.name;

      if(!currentName
        && !this.configuration.campaignSearchType
        || this.configuration.campaignSearchType === 'current'
      ) {
        this.updateConfigurationName(emailName);
        return
      }

      const anyCampaignName = `${emailName} in any campaign`;
      if (this.configuration.campaignSearchType ==='any') {
        this.updateConfigurationName(anyCampaignName);
        return
      }

      const configCampaign = this.campaigns.find((x) => x.id === this.configuration.campaignId)?.name
      if (this.configuration.campaignSearchType ==='other'
       && configCampaign
      ) {
        this.updateConfigurationName(`${emailName} in "${configCampaign}"`);
      }
    },
    updateConfigurationName(name) {
      const configuration = {
        ...this.configuration,
        name,
      };

      this.$emit("update:configuration", configuration);
    },
    async loadCampaigns() {
      this.isLoadingCampaigns = true;
      const isShowArchived = !(this.campaign?.status === "draft" || this.campaign?.status === "scheduled");
      const resp = await this.$rest.campaign.get_collection({
        ignorePagination:true,
        sort: ["startDate:desc"],
        isArchived: isShowArchived ? null : false,
      });

      this.campaigns = resp.data.items;
      if(isShowArchived) {
        this.campaigns = this.campaigns.map(campaign => ({
          ...campaign,
          name: campaign.isArchived ? campaign.name + " [Archived]" : campaign.name,
        }));
      }
      this.isLoadingCampaigns = false;
    },
    onCampaignOptionInput(val) {
      const configuration = {
        ...this.configuration,
        campaignSearchType: val,
      };

      // set campaignId if current
      if (val === 'current') {
        configuration.campaignId = +this.$route.params.id;
      }

      this.$emit("update:configuration", configuration);
    },
    onSelectCreative(creative) {
      this.selectedEmailMessage = creative;
      this.isSelectingEmailMessage = false;
      this.$emit("update:configuration", {
        ...this.configuration,
        creativeId: creative.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group:last-child {
  margin-bottom: 0;
}
</style>
