<template>
  <v-card class="custom-fields-popup-card" height="500px">
    <v-card-title class="px-6 pt-4 pb-4">
      Preview Creative
    </v-card-title>
    <v-divider />

    <v-card-text class="pa-0">
      <v-img
        v-if="availableImageURL"
        :src="availableImageURL"
      />
      <p v-else>
        {{ creative_content }}
      </p>
    </v-card-text>

    <v-card-actions class="divider-with-shadow d-flex justify-end">
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="$emit('dismiss')"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PreviewCreativeDialog",
  props: {
    creative_content: {
      default: () => [],
    },
    creative: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    availableImageURL() {
      if (!this.creative.urls) {
        return null
      }

      return this.creative.urls.large
          ?? this.creative.urls.full
          ?? this.creative.urls.medium
          ?? this.creative.urls.small;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
