export default {
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      transitionHoveredBorderColor: null,
      transitionTextColor: null
    };
  },
  computed: {
    transitionEvents() {
      return {};
    }
  },
  methods: {
    dynamicTransitionBarConfig(transitionEvent, overrideBaseConfig) {
      const isTransitionActive = this.isTransitionActive(transitionEvent);

      const baseConfig = overrideBaseConfig || {
        fill: isTransitionActive
          ? this.transitionHoveredBorderColor || this.nodeColor
          : this.nodeColor,
        height: 23,
        name: "transitionBar",
        offsetY: !this.isSelected ? -96 : -95,
        offsetX: !this.isSelected ? -96 : -95,
        stroke: isTransitionActive
          ? this.nodeFontColor
          : this.transitionHoveredBorderColor || this.nodeFontColor,
        strokeWidth: 1,
        transformsEnabled: "position"
      };

      let configToReturn = Object.assign({}, baseConfig);

      if (this.transitionEvents[transitionEvent]) {
        configToReturn = Object.assign(
          configToReturn,
          this.transitionEvents[transitionEvent].transitionBarConfig || {}
        );
      }

      return configToReturn;
    },

    dynamicTransitionTextConfig(transitionEvent, overrideBaseConfig) {
      const isTransitionActive = this.isTransitionActive(transitionEvent);

      const baseConfig = overrideBaseConfig || {
        align: "center",
        fill: isTransitionActive
          ? this.transitionTextColor || this.nodeFontColor
          : this.nodeFontColor,
        fontSize: 12,
        fontStyle: isTransitionActive ? "bold" : "normal",
        listening: false,
        name: "transitionText",
        offsetY: !this.isSelected ? -100 : -100,
        opacity: isTransitionActive ? 1 : 0.7,
        transformsEnabled: "position"
      };

      let configToReturn = Object.assign({}, baseConfig);

      if (this.transitionEvents[transitionEvent]) {
        configToReturn = Object.assign(
          configToReturn,
          this.transitionEvents[transitionEvent].transitionTextConfig || {}
        );
      }

      return configToReturn;
    },

    isTransitionActive(transitionEvent) {
      return (
        this.transitionHovered === transitionEvent ||
        this.hasTransitionWithEvent(transitionEvent)
      );
    },
    hasTransitionWithEvent(transitionEvent) {
      for (let tuid in this.node.transitions) {
        if (
          this.node.transitions[tuid].event &&
          this.node.transitions[tuid].event === transitionEvent
        ) {
          return true;
        }
      }

      return false;
    },

    onDynamicTransitionBarHover(transitionEvent) {
      this.transitionHovered = transitionEvent;
      this.setCursorPointer();
    },
    onDynamicTransitionBarLeave(transitionEvent) {
      if (this.transitionHovered === transitionEvent) {
        this.transitionHovered = null;
        this.setCursorDefault();
      }
    }
  }
};
