<template>
  <v-group
    ref="node"
    :config="nodeConfig"
    @dragstart="onDragStart"
    @dragmove="onDragging"
    @dragend="onDragEnd"
    @click="onSelect"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <v-rect
      :config="parentConfig"
      @dblclick="openConfiguration"
    />
    <v-image
      :config="removeIconConfig"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
    />
    <v-group
      v-if="!isHovered"
      :config="detailsConfig"
    >
      <v-text :config="nodeNameConfig" />
      <v-text :config="dbNodeConfig" />
      <div v-if="!isShowCounts">
        <v-text :config="nodeLabelConfig" />
      </div>
      <div v-else>
        <v-text :config="nodeCountEnteredLabelConfig" />
        <v-text :config="nodeCountProcessingLabelConfig" />
        <v-text :config="nodeCountCompletedLabelConfig" />
        <v-text :config="nodeCountEndedLabelConfig" />
      </div>
    </v-group>
    <NodeHoverConfiguration
      v-else
      :configured="!!node.config.name"
      @onPreview="togglePreview"
      @onConfig="openConfiguration"
      @onDelete="onRemoveClick"
    />
    <v-group
      v-if="!readOnly && !isShowCounts"
      @mouseenter="onTransitionBarHover"
      @mouseleave="onTransitionBarLeave"
    >
      <v-rect ref="transitionBar" :config="transitionBarConfig" />
      <v-text :config="transitionTextConfig" />
    </v-group>
  </v-group>
</template>

<script>
    import CampaignNodeMixin from "@/mixins/campaign-node-mixin";
    import CampaignMixin from "@/mixins/campaign-mixin";
    import NodeHoverConfiguration from "@/views/Campaigns/components/automation/NodeHoverConfiguration";
    export default {
        name: "ActionAddDelayNode",
        components: {NodeHoverConfiguration},
        mixins: [CampaignNodeMixin, CampaignMixin],
        data() {
            return {
                transitionBarColor: '#666666',
                defaultTransitionHoverEvent: 'delayed',
            };
        },
    }
</script>
