<template>
  <v-group
    ref="node"
    :config="nodeConfig"
    @dragstart="onDragStart"
    @dragmove="onDragging"
    @dragend="onDragEnd"
    @click="onSelect"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <v-rect
      :config="parentConfig"
      @dblclick="openConfiguration"
    />
    <v-image
      :config="removeIconConfig"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="onRemoveClick"
    />
    <v-group
      v-if="!isHovered"
      :config="detailsConfig"
    >
      <v-text :config="nodeNameConfig" />
      <v-text
        :config="primaryIconConfig"
      />
      <div v-if="!isShowCounts">
        <v-image
          v-if="nodeImageUrl"
          :config="nodeIconConfig"
        />
        <v-text :config="nodeLabelConfig" />
      </div>
      <div v-else>
        <v-text :config="nodeCountEnteredLabelConfig" />
        <v-text :config="nodeCountProcessingLabelConfig" />
        <v-text :config="nodeCountCompletedLabelConfig" />
        <v-text :config="nodeCountEndedLabelConfig" />
      </div>
    </v-group>
    <NodeHoverConfiguration
      v-else
      :configured="!!node.config.name"
      @onPreview="togglePreview"
      @onConfig="openConfiguration"
      @onDelete="onRemoveClick"
    />
    <v-group
      v-if="!readOnly && !isShowCounts"
      @mouseenter="onTransitionBarHover"
      @mouseleave="onTransitionBarLeave"
    >
      <v-rect ref="transitionBar" :config="transitionBarConfig" />
      <v-text :config="transitionTextConfig" />
    </v-group>
    <NodeStatistics :node="node" />
  </v-group>
</template>

<script>
import CampaignNodeMixin from "@/mixins/campaign-node-mixin";
import CampaignMixin from "@/mixins/campaign-mixin";
import NodeStatistics from "./NodeStatistics";
import NodeHoverConfiguration from "@/views/Campaigns/components/automation/NodeHoverConfiguration";

export default {
  name: "TriggerSegmentNode",
  components: {NodeHoverConfiguration, NodeStatistics},
  mixins: [CampaignNodeMixin, CampaignMixin],
  data() {
    return {
      canBeConnectedTo: false,
      transitionBarColor: '#2b79c0',
      defaultTransitionHoverEvent: 'entered',
    };
  },
  computed: {
    nodeImageUrl() {
      if (!this.node?.config?.segmentId) {
        return null;
      }

      return this.segments.find(segment => segment.id === this.node.config.segmentId)?.imageUrl;
    },
    primaryIconConfig() {
      return {
        fill: '#FFF',
        fontFamily: 'FontAwesome',
        fontSize: 35,
        listening: false,
        name: 'primaryIcon',
        offsetX: 93,
        offsetY: 22,
        text: "\uf0c0",
        transformsEnabled: 'position',
        visible: true,
      };
    },
  },
  mounted() {
    if (this.nodeImageUrl) {
      this.nodeIconObj.src = this.nodeImageUrl;
    }
  },
}
</script>
