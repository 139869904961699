<template>
  <div>
    <div class="notification-menu__header">
      Segment
    </div>
    <CustomDropdown
      autocomplete
      placeholder="Select an Option"
      class="mt--2"
      :value="configuration.segmentId"
      :items="segments"
      item-value="id"
      item-text="name"
      @change="change_segmentId"
    />

    <div class="notification-menu__header mt-7">
      Trigger Type
    </div>
    <v-radio-group
      :value="configuration.condition"
      class="custom-field-radio-group"
      @change="
        $emit('update:configuration', {
          ...configuration,
          condition: $event,
        })
      "
    >
      <v-radio value="all" label="Add all contacts in this segment" />
      <v-radio
        label="Add contacts that enter this segment in the future"
        value="future"
      />
      <v-radio
        label="Add all contacts in this segment, and any contacts that enter it in the future"
        value="hybrid"
      />
    </v-radio-group>
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "TriggerSegmentConfiguration",
  components: {
    CustomDropdown,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      default() {
        return {}
      }
    },
    segments: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    change_segmentId(val) {
      const segmentName = this.segments.find((x) => x.id === val)?.name;
      this.$emit("update:configuration", 
        {
          ...this.configuration,
          segmentId: val,
          name: segmentName ?? ''
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-field-placeholder {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
}
</style>
