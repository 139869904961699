<template>
  <div />
</template>

<script>

    export default {
        name: "ActionUpdateFieldConfiguration",
        components: {
        },
        props: {
            configuration: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {

            };
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

