<template>
  <v-group
    v-if="has_preview"
    :config="{ 
      offsetY: readOnly ? -30 : -7,
      offsetX: -52,
      width: 200
    }"
  >
    <v-group
      :config="{
        width: 100,
      }"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="$emit('onPreview')"
    >
      <v-image :config="iconConfig" />
      <v-text
        :config="{
          text: 'Preview',
          fontSize: 11,
          offsetY: -1,
          offsetX: -21,
          fontWeight: 200,
          fontFamily: 'Open Sans',
          fill: '#ffffff',
        }"
      />
    </v-group>
    <v-group
      :config="{
        offsetY: -29,
        width: 100,
      }"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="$emit('onConfig')"
    >
      <v-image :config="pencilIconConfig" />
      <v-text
        :config="{
          text: configuredLabel,
          fontSize: 11,
          offsetY: -1,
          offsetX: -21,
          fontWeight: 200,
          fontFamily: 'Open Sans',
          fill: '#ffffff',
        }"
      />
    </v-group>
    <v-group
      v-if="!readOnly"
      :config="{
        offsetY: -58,
        width: 100,
      }"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="$emit('onDelete')"
    >
      <v-image :config="trashIconConfig" />
      <v-text
        :config="{
          text: 'Delete',
          fontSize: 11,
          offsetY: -1,
          offsetX: -21,
          fontWeight: 200,
          fontFamily: 'Open Sans',
          fill: '#ffffff',
        }"
      />
    </v-group>
  </v-group>
  <v-group
    v-else
    :config="{ 
      offsetY: readOnly ? -50 : -25,
      offsetX: -52,
      width: 200
    }"
  >
    <v-group
      :config="{
        offsetY: 0,
        width: 100,
      }"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="$emit('onConfig')"
    >
      <v-image :config="pencilIconConfig" />
      <v-text
        :config="{
          text: configuredLabel,
          fontSize: 11,
          offsetY: -1,
          offsetX: -21,
          fontWeight: 200,
          fontFamily: 'Open Sans',
          fill: '#ffffff',
        }"
      />
    </v-group>
    <v-group
      v-if="!readOnly"
      :config="{
        offsetY: -29,
        width: 100,
      }"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="$emit('onDelete')"
    >
      <v-image :config="trashIconConfig" />
      <v-text
        :config="{
          text: 'Delete',
          fontSize: 11,
          offsetY: -1,
          offsetX: -21,
          fontWeight: 200,
          fontFamily: 'Open Sans',
          fill: '#ffffff',
        }"
      />
    </v-group>
  </v-group>
</template>

<script>
import default_icons from "@/assets/icons/automated/default_icons";
import CampaignMixin from "@/mixins/campaign-mixin";

export default {
  name: "NodeHoverConfiguration",
  mixins: [CampaignMixin],
  props: {
    has_preview: {
      default: () => false,
    },
    configured: {
      default: () => false,
    },
  },
  data() {
    return {
      testImg: new Image(100, 100),
      trashImg: new Image(100, 100),
      pencilImg: new Image(100, 100),
    };
  },
  computed: {
    iconConfig: {
      get() {
        return {
          image: this.testImg,
          width: 12,
          height: 10,
        };
      },
    },
    pencilIconConfig: {
      get() {
        return {
          image: this.pencilImg,
          width: 12,
          height: 12,
        };
      },
    },
    trashIconConfig: {
      get() {
        return {
          image: this.trashImg,
          width: 11,
          height: 12,
        };
      },
    },
    configuredLabel() {
      if (this.readOnly) {
        return "View Config";
      } else {
        return this.configured ? "Reconfigure" : "Configure";
      }
    },
  },
  mounted() {
    this.testImg.src = default_icons.Eye;
    this.trashImg.src = default_icons.Trash;
    this.pencilImg.src = default_icons.Pencil;
  },
  methods: {
    setCursorPointer(ev) {
      ev.currentTarget.getStage().container().style.cursor = "pointer";
    },
    // Sets the cursor to the default style
    setCursorDefault(ev) {
      ev.currentTarget.getStage().container().style.cursor = "default";
    },
  },
};
</script>
