<template>
  <div>
    <div class="mb-5">
      <div class="field__header">
        <span class="field__subheader--required">*</span>
        From: Who is sending this email?
      </div>
      <CustomDropdown
        v-if="!isLoadingFromLines"
        placeholder="Select an Option"
        :value="configuration.fromLineId"
        :items="fromLinesToShow"
        item-value="id"
        item-text="text"
        @change="
          $emit('update:configuration', {
            ...configuration,
            fromLineId: $event,
          })
        "
      />
      <div v-else class="loading-field-placeholder">
        <div>Loading From Lines...</div>
      </div>
    </div>
    <div
      v-if="!isLoadingFromLines && configuration.fromLineId === 'SEND_FROM_REP'"
      class="mb-5"
    >
      <div class="field__header">
        <span class="field__subheader--required">*</span>
        From Line for contacts without a rep
      </div>
      <CustomDropdown
        :value="configuration.backupFromLineId"
        required
        item-text="text"
        item-value="id"
        subheader="Contacts without a rep will receive the email from this option"
        placeholder="From Who Else"
        :items="backupFromLineOptions"
        @change="
          $emit('update:configuration', {
            ...configuration,
            backupFromLineId: $event,
          })
        "
      />
    </div>

    <SimpleCampaignDetails
      type="split-test"
      :campaign_config.sync="get_campaign_config"
      :campaign="campaign"
      :selected_contact_type_key1.sync="selected_contact_type_key1"
      :selected_contact_type_key2.sync="selected_contact_type_key2"
      :selected_contact_type_key3.sync="selected_contact_type_key3"
      :selected_existing_campaign.sync="selected_existing_campaign"
      :activate_testing="true"
      :in_modal="true"
    />

    <v-switch
      :input-value="configuration.winnerUsesBestTime"
      class="custom-switch"
      inset
      label="Use Best Day/Time"
      @change="
        $emit('update:configuration', {
          ...configuration,
          winnerUsesBestTime: $event,
        })
      "
    />
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import SimpleCampaignDetails from "@/views/Campaigns/components/SimpleCampaignDetails";

export default {
  name: "ActionSendEmailSplitTestConfiguration",
  components: {
    CustomDropdown,
    SimpleCampaignDetails,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fromLines: [],
      available_creatives: [],
      selected_existing_campaign: {},
      type: "split-test",
      campaign_config: {
        testSizePercent: 15,
        segmentId: undefined,
        winnerMetric: undefined,
        sendWinningEmail: undefined,
        analysisPhaseDelay: undefined,
        phase: "test",
        type: "AB",
        testType: "subject",
        fromLineId: undefined,
        creativeId: undefined,
        subject: undefined,
        previewText: undefined,
        tests: {
          A: {
            creativeId: undefined,
            subject: undefined,
            previewText: undefined,
          },
          B: {
            creativeId: undefined,
            subject: undefined,
            previewText: undefined,
          },
          C: {
            creativeId: undefined,
            subject: undefined,
            previewText: undefined,
          },
        },
      },
      isLoadingFromLines: false,
    };
  },
  computed: {
    selected_contact_type_key1: {
      get() {
        if(this.available_creatives.length == 0) return

        const id = this.campaign_config.tests.A.creativeId;
        if (!id) return null

        return this.available_creatives.find(c => c.id == id);
      },
      set(creative) {
        if(!creative.id) return
        this.campaign_config.tests.A.creativeId = creative.id;
      }
    },
    selected_contact_type_key2: {
      get() {
        if(this.available_creatives.length == 0) return

        const id = this.campaign_config.tests.B.creativeId;
        if (!id) return null

        return this.available_creatives.find(c => c.id == id);
      },
      set(creative) {
        if(!creative.id) return
        this.campaign_config.tests.B.creativeId = creative.id;
      }
    },
    selected_contact_type_key3: {
      get() {
        if(this.available_creatives.length == 0) return

        const id = this.campaign_config.tests.C?.creativeId;
        if (!id) return null

        return this.available_creatives.find(c => c.id == id);
      },
      set(creative) {
        if(!creative.id) return
        this.campaign_config.tests.C.creativeId = creative.id;
      }
    },
    fromLinesToShow() {
      return this.fromLines.map(i => ({
        text: i.fromEmail ? `${i.fromName}<${i.fromEmail}>` : `${i.fromName}`,
        id: i.id,
      }));
    },
    backupFromLineOptions() {
      return this.fromLinesToShow.filter(i => i.id !== 'SEND_FROM_REP');
    },
    get_campaign_config: {
      get() {
        return this.campaign_config;
      },
      set(config) {
        this.campaign_config = config;

        this.$emit("update:configuration", {
          ...config,
          fromLineId: config.fromLineId ?? this.configuration.fromLineId,
          creativeId: config.creativeId ?? this.configuration.creativeId,
          testSize: config.testSize,
          testSizePercent: config.testSizePercent,
          testType: config.testType,
          sendWinningEmail: config.sendWinningEmail,
          winnerMetric: config.winnerMetric,
          analysisPhaseDelay: config.analysisPhaseDelay,
          phase: "test",
          winnerUsesBestTime: this.configuration.winnerUsesBestTime,
        });
      },
    },
  },
  watch: {
    selected_existing_campaign: function (val) {
      this.onSelectCreative(val);
    },
    'selected_existing_campaign.id': {
      handler() {
        this.updateConfigurationNameOnChange();
      },
    },
    'campaign_config.type': {
      handler() {
        this.updateConfigurationNameOnChange();
      },
    },
    'campaign_config.analysisPhaseDelay': {
      handler() {
        this.updateConfigurationNameOnChange();
      },
    },
  },
  async mounted() {
    this.initialize_campaign_config();
    await this.getFromLines();
    await this.load_creatives();
    if (this.campaign_config.creativeId) {
      this.selected_existing_campaign = this.available_creatives.find(
        (creative) => creative.id == this.campaign_config.creativeId
      );
    }
  },
  methods: {
    updateConfigurationNameOnChange() {
      if (
        this.campaign_config.type &&
        (this.campaign_config.analysisPhaseDelay ||
          this.campaign_config.analysisPhaseDelay === 0
        )
      ) {
        let type =
          this.campaign_config.type === "AB" ? "A/B Test" : "A/B/C Test";
        let delay =
          this.campaign_config.analysisPhaseDelay === 0
            ? "Immediately"
            : this.campaign_config.analysisPhaseDelay === -1
            ? "Never"
            : `${this.campaign_config.analysisPhaseDelay} hours`;
        let label = `${type} - ${delay}`;

        this.get_campaign_config = {
          ...this.get_campaign_config,
          name: label,
        }
        this.updateConfigurationName(label)
      } else if (this.selected_existing_campaign && this.selected_existing_campaign.id) {
        this.get_campaign_config = {
          ...this.get_campaign_config,
          name: this.selected_existing_campaign.name,
        }
        this.updateConfigurationName(this.selected_existing_campaign.name)
      }
    },
    updateConfigurationName(name) {
      this.$emit("update:configuration", {
        ...this.configuration,
        name,
      });
    },
    initialize_campaign_config() {
      this.campaign_config.name = this.configuration?.name;
      this.campaign_config.type = this.configuration.type;
      this.campaign_config.testType = this.configuration.testType;
      this.campaign_config.sendWinningEmail =
        this.configuration.sendWinningEmail;
      this.campaign_config.winnerMetric = this.configuration.winnerMetric;
      this.campaign_config.analysisPhaseDelay =
        this.configuration.analysisPhaseDelay;
      this.campaign_config.winnerUsesBestTime =
        this.configuration.winnerUsesBestTime;
      this.campaign_config.testSize = this.configuration.testSize;
      this.campaign_config.testSizePercent = this.configuration.testSizePercent;
      this.campaign_config.creativeId = this.configuration.creativeId;

      if (this.configuration.previewText) {
        this.campaign_config.previewText = this.configuration.previewText;
      }
      if (this.configuration.subject) {
        this.campaign_config.subject = this.configuration.subject;
      }
      if (this.configuration.tests?.A?.subject) {
        this.campaign_config.tests.A.subject =
          this.configuration.tests.A.subject;
      }
      if (this.configuration.tests?.A?.previewText) {
        this.campaign_config.tests.A.previewText =
          this.configuration.tests.A.previewText;
      }
      if (this.configuration.tests?.A?.creativeId) {
        this.campaign_config.tests.A.creativeId =
          this.configuration.tests.A.creativeId;
      }
      if (this.configuration.tests?.B?.subject) {
        this.campaign_config.tests.B.subject =
          this.configuration.tests.B.subject;
      }
      if (this.configuration.tests?.B?.previewText) {
        this.campaign_config.tests.B.previewText =
          this.configuration.tests.B.previewText;
      }
      if (this.configuration.tests?.B?.creativeId) {
        this.campaign_config.tests.B.creativeId =
          this.configuration.tests.B.creativeId;
      }
      if (this.configuration.type === "ABC") {
        if (this.configuration.tests?.C?.subject) {
          this.campaign_config.tests.C.subject =
            this.configuration.tests.C.subject;
        }
        if (this.configuration.tests?.C?.previewText) {
          this.campaign_config.tests.C.previewText =
            this.configuration.tests.C.previewText;
        }
      } else {
        delete this.campaign_config.tests.C;
      }
    },
    async getFromLines() {
      this.isLoadingFromLines = true;

      this.fromLines = await this.$store.dispatch('automation/getCampaignFromLines');

      this.isLoadingFromLines = false;

      if (
        !this.configuration.fromLineId ||
        !this.fromLines.find(
          (x) => x.id === this.configuration.fromLineId
        )
      ) {
        const defaultFromLine = this.fromLines.find(
          (x) => x.isDefault
        );
        this.$emit("update:configuration", {
          ...this.configuration,
          fromLineId: defaultFromLine.id,
        });
      }
    },
    async load_creatives() {
      this.available_creatives = (
        await this.$rest.creative.email.get_collection({
          isArchived: false,
          ignorePagination: true,
          htmlContentExist: true,
        })
      ).data.items;
    },
    async onSelectCreative(creative) {
      if (creative && creative.id) {
        const config = this.configuration;
        if (config.type === "AB") {
          config.tests.A.creativeId = creative.id;
          config.tests.B.creativeId = creative.id;
          delete config.tests.C;
        } else if (config.type === "ABC") {
          config.tests.A.creativeId = creative.id;
          config.tests.B.creativeId = creative.id;
          config.tests.C.creativeId = creative.id;
        }

        this.$emit("update:configuration", {
          ...config,
          creativeId: creative.id,
        });
      }
    },
  },
};
</script>
