<template>
  <div class="custom-text-input custom-dropdown">
    <div v-if="header" :class="headerClass || 'field__subheader d-flex'">
      <span
        v-if="required"
        class="field__subheader--required"
      >
        *
      </span>
      {{ header }}
      <slot name="icon" />
    </div>
    <div v-if="subheader" class="field__subheader field__subheader_item pt-2">
      {{ subheader }}
    </div>
    <validation-observer ref="textInput">
      <validation-provider
        v-slot="{ errors }"
        :rules="{
          required
        }"
        mode="eager"
      >
        <v-select
          v-if="!autocomplete"
          ref="dropdown"
          :readonly="readonly"
          :value="value"
          :placeholder="placeholder"
          :multiple="multiple"
          dense
          :items="items"
          :class="dropdownClass || 'custom-dropdown mt-4'"
          outlined
          :error-messages="errors"
          :menu-props="{
            offsetY: true,
            'content-class': 'custom-dropdown',
          }"
          :item-value="itemValue || 'value'"
          :item-text="itemText || 'label'"
          :return-object="returnObject"
          :hint="hint"
          persistent-hint
          append-icon="mdi-chevron-down mt-1"
          @change="(newValue) => $emit('change', newValue)"
          @input="inputEvent"
        >
          <template v-if="short" #selection="{ item, index}">
            <span
              v-if="index === 0"
              class="short-text"
              :class="{'has-more': value.length > 1}"
            >
              {{ item[itemText || 'label'] }}
            </span>
            <span v-if="index === 1">
              (+{{ value.length - 1 }} others)
            </span>
          </template>
        </v-select>
        <v-autocomplete
          v-else
          ref="dropdown"
          :readonly="readonly"
          :value="value"
          :placeholder="placeholder"
          :multiple="multiple"
          dense
          :items="items"
          :class="dropdownClass || 'custom-dropdown mt-4'"
          outlined
          :error-messages="errors"
          :menu-props="{
            offsetY: true,
            contentClass: 'custom-dropdown',
          }"
          :item-value="itemValue || 'value'"
          :item-text="itemText || 'label'"
          :return-object="returnObject"
          :hint="hint"
          persistent-hint
          append-icon="mdi-chevron-down mt-1"
          @change="(newValue) => $emit('change', newValue)"
          @input="inputEvent"
        >
          <template v-if="short" #selection="{ item, index}">
            <span
              v-if="index === 0"
              class="short-text"
              :class="{'has-more': value.length > 1}"
            >
              {{ item[itemText || 'label'] }}
            </span>
            <span v-if="index === 1">
              (+{{ value.length - 1 }} others)
            </span>
          </template>
        </v-autocomplete>
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "CustomDropdown",
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    short: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "",
    },
    headerClass: {
      type: String,
      default: "",
    },
    dropdownClass: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    subheader: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean, 
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      default: () => null,
    },
    itemValue: {
      default: () => null,
    },
    itemText: {
      default: () => null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    required: {
      type: [Boolean, Object],
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    hint: {
      default: () => "",
      type: String,
    },
  },
  methods: {
    inputEvent(newVal) {
      this.$refs.dropdown.setMenuIndex(-1);
      this.$emit('input', newVal);
    },
  },
};
</script>

<style lang="scss">
.custom-dropdown {
  .v-input__slot {
    .v-select__selections {
      .v-select__selection {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    .v-input__icon {
      i {
        color: #4c4c4c !important;
        font-size: 15px;
      }
    }
  }

  .v-list-item--disabled {
    .theme--light.v-icon {
      color: #dddddd;
    }
    .v-list-item__title {
      color: #aaaaaa;
    }
  }

  .v-list-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
  }

  .v-input__append-inner {
    margin-right: -6px;
    padding-left: 0 !important;
  }
}

.custom-text-input {
  fieldset {
    border-radius: 4px;
    border: solid 1px #dee7ee;
    background-color: #ffffff;
  }
  input {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9f9fb0;
  }
  .v-input__slot {
    max-height: 44px !important;
    min-height: 44px !important;
    height: 44px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-text-field__details {
    min-height: auto;

    .v-messages {
      min-height: auto;
    }
  }

  .v-input--is-readonly {
    fieldset {
      background-color: rgba(0, 0, 0, 0.1);
    }
    .v-input__slot {
      .v-select__selections {
        .v-select__selection {
          color: rgba(0, 0, 0, 0.87);
        }
      }

      .v-input__icon {
        i {
          color: rgba(0, 0, 0, 0.5) !important;
        }
      }
    }
  }
}

.field__subheader--required {
  color: #e74c3c;
}

.field__subheader {
  font-family: Open Sans,sans-serif;
  line-height: normal;
  color: #66788e;
  font-size: 13px;
}

.short-text {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.has-more {
    max-width: calc(100% - 80px);
  }
}
</style>
