<template>
  <v-dialog :value="dialogState" width="500">
    <v-card>
      <v-card-title> Campaign name </v-card-title>
      <v-card-text>
        <CustomTextInput
          :value="localCampaignName"
          class="flex-grow-1"
          placeholder="Enter name"
          :maxlength="150"
          required
          @input="localCampaignName = $event"
        />
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-btn
          depressed
          class="simple-text-button button--gray px-9 ml-auto"
          @click="$emit('close')"
        >
          Cancel
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          :disabled="!campaignName"
          @click="changeCampaignName"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "ChangeCampaignNameDialog",
  components: {
    CustomTextInput,
  },
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    campaignName: {
      type: String,
      default: ""
    },
    dialogState: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      localCampaignName: this.campaignName
    }
  },
  watch: {
    campaignName: function (val) {
      this.localCampaignName = val
    },
  },
  methods: {
    async changeCampaignName() {
      await this.$rest.campaign.put_resource(this.id, {
        name: this.localCampaignName,
      });
      this.$emit("done", this.localCampaignName);
      this.$emit("close");
    },
  }

}
</script>