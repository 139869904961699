<template>
  <div class="input-with-button">
    <v-text-field
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      dense
      depressed
      height="44px"
      outlined
      :disabled="disable_search_field"
      :placeholder="placeholder"
      @keydown.enter="!disable_search_on_enter && $emit('search', search)"
    >
      <template #append>
        <v-btn
          v-if="!disable_search_field"
          height="34px"
          width="75px"
          class="custom-button custom-button--blue"
          @click="$emit('search', search)"
        >
          Search
        </v-btn>
        <div v-else style="width: 75px;" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "SearchField",
  props: {
    value: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => {},
      type: String,
    },
    disable_search_field: {
      default: () => false,
      type: Boolean,
    },
    disable_search_on_enter: {
      default: () => false,
      type: Boolean,
    },
  },
  computed: {
    search: {
      get() {
        return this.value || "";
      },
      set(text) {
        this.$emit("input", text);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.input-with-button {
  min-width: 260px;
  width: 100%;

  ::v-deep {
    .v-text-field__details {
      display: none;
    }
    .v-text-field {
      fieldset {
        border-radius: 4px;
        border: solid 1px #dee7ee;
      }
    }
    .v-input__prepend-inner {
      margin-top: 9px !important;
      i {
        color: #2b84eb !important;
      }
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-input__slot {
      padding-right: 5px !important;
      margin-bottom: 0 !important;
    }
    input {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #9f9fb0;
    }
  }
}
</style>
