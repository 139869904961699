<template>
  <div>
    <div>
      <div class="notification-menu__header">
        <span class="field__subheader--required">*</span>
        From: Who is sending this email?
      </div>
      <CustomDropdown
        v-if="!isLoadingFromLines"
        placeholder="Select an Option"
        :value="configuration.fromLineId"
        :items="fromLinesToShow"
        item-text="text"
        item-value="id"
        class="mt--2"
        required
        @change="
          $emit('update:configuration', {
            ...configuration,
            fromLineId: $event,
          })
        "
      />
      <div v-else class="loading-field-placeholder">
        <div>Loading From Lines...</div>
      </div>
    </div>
    <div
      v-if="!isLoadingFromLines && configuration.fromLineId === 'SEND_FROM_REP'"
      class="mt-7"
    >
      <div class="notification-menu__header">
        <span class="field__subheader--required">*</span>
        From Line for contacts without a rep
      </div>
      <CustomDropdown
        :value="configuration.backupFromLineId"
        required
        item-text="text"
        item-value="id"
        subheader="Contacts without a rep will receive the email from this option"
        placeholder="From Who Else"
        :items="backupFromLineOptions"
        @change="
          $emit('update:configuration', {
            ...configuration,
            backupFromLineId: $event,
          })
        "
      />
    </div>

    <div class="notification-menu__header mt-7">
      <span class="field__subheader--required">*</span>
      Subject Line
    </div>
    <CustomTextInput
      :value="configuration.subject"
      required
      class="mt--2"
      placeholder="Subject Line"
      @change="
        $emit('update:configuration', {
          ...configuration,
          subject: $event,
        })
      "
    />

    <div class="notification-menu__header mt-7">
      Preview Text
    </div>
    <CustomTextInput
      :value="configuration.previewText"
      :maxlength="150"
      class="mt--2"
      placeholder="Preview Text"
      @change="
        $emit('update:configuration', {
          ...configuration,
          previewText: $event,
        })
      "
    />

    <div class="notification-menu__header mt-7">
      <span class="field__subheader--required">*</span>
      Content
    </div>
    <div
      v-if="selectedEmailMessage && selectedEmailMessage.id"
      class="d-flex align-center justify-center mt-2 mb-5"
    >
      <v-img
        max-height="95px"
        max-width="95px"
        contain
        class="mr-3"
        :src="selectedEmailMessage.imageFileUrls.small"
      />
      <div class="ml-3">
        <div class="campaigns-table__campaign-header">
          {{ selectedEmailMessage.name }}
        </div>
        <small class="text--disabled" style="font-size: 11px">
          ID: {{ selectedEmailMessage.id }}
        </small>
      </div>
    </div>
    <div class="d-flex justify-center mt-2">
      <v-btn
        height="34px"
        class="custom-button custom-button--blue"
        width="150px"
        @click="choose_existing_dialog_opened = true"
      >
        Select Content
      </v-btn>
    </div>

    <v-switch
      :input-value="configuration.useBestDay"
      class="custom-switch"
      inset
      label="Use Best Day/Time"
      @change="
        $emit('update:configuration', {
          ...configuration,
          useBestDay: $event,
        })
      "
    />

    <v-dialog
      v-model="choose_existing_dialog_opened"
      scrollable
      max-width="900px"
    >
      <ChooseExistingCampaignDialog
        v-if="choose_existing_dialog_opened"
        :selected_item_id="configuration.creativeId"
        @dismiss="choose_existing_dialog_opened = false"
        @select="(data) => onSelectCreative(data)"
      />
    </v-dialog>
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import ChooseExistingCampaignDialog from "@/views/Campaigns/components/ChooseExistingCampaignDialog";

export default {
  name: "ActionSendEmailConfiguration",
  components: {
    CustomDropdown,
    CustomTextInput,
    ChooseExistingCampaignDialog,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fromLines: [],
      isLoadingFromLines: false,
      choose_existing_dialog_opened: false,
      selectedEmailMessage: null,
    };
  },
  computed: {
    fromLinesToShow() {
      return this.fromLines.map(i => ({
        text: i.fromEmail ? `${i.fromName}<${i.fromEmail}>` : `${i.fromName}`,
        id: i.id,
      }));
    },
    backupFromLineOptions() {
      return this.fromLinesToShow.filter(i => i.id !== 'SEND_FROM_REP');
    },
  },
  watch: {
    'selectedEmailMessage.name': {
      handler(val) {
        this.$emit('update:configuration', {
          ...this.configuration,
          name: val,
        });
      },
    },
  },
  async mounted() {
    await this.getFromLines();

    if (this.configuration.creativeId) {
      this.selectedEmailMessage = (await this.$rest.creative.email.get_resource(this.configuration.creativeId)).data;
    }
  },
  methods: {
    async getFromLines() {
      this.isLoadingFromLines = true;

      this.fromLines = await this.$store.dispatch('automation/getCampaignFromLines');

      this.isLoadingFromLines = false;

      if (
        !this.configuration.fromLineId ||
        !this.fromLines.find((x) => x.id === this.configuration.fromLineId)
      ) {
        const defaultFromLine = this.fromLines.find((x) => x.isDefault);
        this.$emit("update:configuration", {
          ...this.configuration,
          fromLineId: defaultFromLine.id,
        });
      }
    },

    async onSelectCreative(creative) {
      this.choose_existing_dialog_opened = false;
      this.selectedEmailMessage = creative;
      this.$emit("update:configuration", {
        ...this.configuration,
        creativeId: creative.id,
      });
    },
  },
};
</script>
