<template>
  <v-line ref="dragGuide" :config="dragGuide" />
</template>

<script>
    export default {
        name: "SnapGuide",
        props: {
            type: {
                type: String,
                required: true,
            },
        },
        computed: {
            dragGuide() {
                const pointsGetter = this.type.toLowerCase().trim() === 'vertical' ? 'getVerticalDragGuidePoints' : 'getHorizontalDragGuidePoints';
                const snapChecker = this.type.toLowerCase().trim() === 'vertical' ? 'canSnapVertically' : 'canSnapHorizontally';

                return {
                    dash: [10, 5],
                    lineJoin: 'round',
                    opacity: this.$store.getters['automation/' + snapChecker] ? 0.7 : 0.1,
                    points: this.$store.getters['automation/' + pointsGetter],
                    stroke: '#403f3c',
                    strokeWidth: 5,
                    visible: this.$store.getters['automation/isNodeBeingDragged'],
                };
            },
        },
    }
</script>
