<template>
  <v-group
    ref="transition"
    :config="transitionConfig"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <v-rect :config="transitionHoverArea" />
    <v-arrow :config="transitionArrow" />
    <v-circle :config="transitionDot" />
    <v-group
      v-if="transition.transitionCount === 1"
      :config="{...getTransitionCenterPosition, rotation: degrees, scale: flipTextAndIcon}"
    >
      <v-text ref="text" :config="{...transitionTextConfig}" />
      <v-group
        v-if="isHovered && !readOnly"
        @mouseenter="onRemoveIconEnter"
        @mouseleave="onRemoveIconLeave"
      >
        <v-text
          v-if="typeof transition.startNode.ui.transitionEvents === 'object'"
          :config="configIconConfig"
          @click="handleIconClick"
        />
        <v-text
          v-else
          :config="removeIconConfig"
          @click="handleIconClick"
        />
      </v-group>
    </v-group>
  </v-group>
</template>

<script>
import {getOuterPositionForTransition, getAngleBetweenPoints} from "@/sharedComponents/automation-calculations";
import CampaignMixin from "@/mixins/campaign-mixin";

export default {
  name: "Transition",
  mixins: [CampaignMixin],
  props: {
    transition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isHovered: false,
      isRemoveIconHovered: false,
      height: 50,
      pointerLength: 10,
      transitionTargetOffset: 0,
      uiElement: null,
      rotation:'',
    };
  },
  computed: {
    getTransitionCenterPosition() {
      const transitionPoints = this.getTransitionPointsForVisibleLine();

      // Determine the center coordinates of the line
      const centerX = (transitionPoints.startPos.x + transitionPoints.targetPos.x) / 2;
      const centerY = (transitionPoints.startPos.y + transitionPoints.targetPos.y) / 2;
      return {x: centerX, y: centerY};
    },
    flipTextAndIcon(){
      return 90 > this.degrees && this.degrees  > -90 ? {x:1, y: 1} : {x:-1, y: -1}
    },
    transitionColor(){
      if (this.isHovered){
        return '#2b84eb'
      } else {
        return this.transition.startNode.ui ? this.transition.startNode.ui.color : '#2b84eb'
      }
    },
    degrees() {
      const points = this.getTransitionPointsForVisibleLine();
      return getAngleBetweenPoints(points.startPos.x, points.startPos.y, points.targetPos.x, points.targetPos.y);
    },
    removeIconConfig() {
      return {
        fill: '#66788E',
        fontFamily: 'Material Design Icons',
        fontSize: 18,
        name: 'removeIcon',
        text: "\u{F0A7A}",
        transformsEnabled: 'position',
        align:'center',
        offsetX: 15,
        offsetY: -5,
      };
    },
    configIconConfig() {
      return {
        fill: '#66788E',
        fontFamily: 'Material Design Icons',
        fontSize: 18,
        name: 'configIcon',
        offsetX: 15,
        offsetY: -5,
        text: "\u{F08BB}",
        transformsEnabled: 'position',
      };
    },
    slope() {
      const points = this.getTransitionPointsForVisibleLine();
      const startPos = points.startPos;
      const targetPos = points.targetPos;

      // Calculate the slope that the angle is drawn at to help center the text at
      // the center of the line
      let slope = (targetPos.y - startPos.y) / (targetPos.x - startPos.x);
      return (slope == 'Infinity' || slope == '-Infinity') ? 0 : slope;
    },
    transitionArrow() {
      return {
        fill: this.transitionColor,
        hitStrokeWidth: 0,
        listening: false,
        pointerLength: 8,
        pointerWidth: 8,
        points: this.transitionPoints,
        shadowForStrokeEnabled: false,
        stroke: this.transitionColor,
        strokeWidth: 2,
      };
    },
    transitionDot(){
      return {
        fill: this.transitionColor,
        listening: false,
        position: {x: this.transitionPoints[0], y: this.transitionPoints[1]},
        shadowForStrokeEnabled: false,
        stroke: this.transitionColor,
        radius: 4,
      };
    },
    transitionConfig() {
      return {
        draggable: false,
        listening: !this.$store.state.automation.transitionBeingCreated,
        name: 'fullTransition-' + this.transition.tuid,
        type: 'Transition',
      };
    },
    transitionPoints() {
      const startNode = this.getNodeById(this.transition.startNode.nuid);
      const targetNode = this.getNodeById(this.transition.targetNode.nuid);

      const startPos = getOuterPositionForTransition(targetNode.position,startNode.position, -9);
      const targetPos = getOuterPositionForTransition(startNode.position, targetNode.position, this.transitionTargetOffset);

      return [startPos.x + 97, startPos.y + 60, targetPos.x + 97, targetPos.y + 60];
    },
    transitionHoverArea() {
      const points = this.getTransitionPointsForVisibleLine();
      const startPos = points.startPos;
      const targetPos = points.targetPos;

      const degrees = this.degrees;

      // How far away the area should be from the line drawn
      // Will be half of the area's height to make sure half the box is on each side
      // of the line drawn
      const distanceAwayFromLine = - (this.height / 2);

      // Find the perpendicular angle relative to the angle that the line is drawn at
      // This is used to find the position of the area at the distance provided above
      let areaDirection = degrees + 90;
      if(areaDirection > 180)
      {
        areaDirection -= 360;
      }

      // Get the distance between the start and target positions which represent
      // the area's width
      const diffX = targetPos.x - startPos.x;
      const diffY = targetPos.y - startPos.y;
      const distanceBetweenPoints = Math.sqrt(diffX*diffX + diffY*diffY);

      // Find the area's position by "pushing" the line's start coordinates by the distance
      // that the area should be away from the line at the perpendicular angle
      const areaPosition = {
        x: distanceAwayFromLine * Math.cos(areaDirection * Math.PI / 180) + startPos.x,
        y: distanceAwayFromLine * Math.sin(areaDirection * Math.PI / 180) + startPos.y,
      };

      return {
        height: this.height,
        name: 'transitionHoverArea',
        position: areaPosition,
        rotation: degrees,
        width: distanceBetweenPoints,
      };
    },
    transitionText() {
      //define how many same transitions we have
      let transitionsCount=Object.values(this.$store.state.automation.transitions).filter( i => i.startNode === this.transition.startNode && i.targetNode === this.transition.targetNode).length
      return transitionsCount > 1 ? `${transitionsCount} Actions` : this.transition.text || '';
    },
    transitionTextConfig() {
      if(!this.transitionText)
      {
        return {
          name: 'transitionText',
        };
      }

      return {
        fill: this.isHovered ? '#2b84eb':'#66788e',
        align: "center",
        fontFamily: 'Open Sans',
        fontSize: 13,
        name: 'transitionText',
        text: this.transitionText,
        width: 120,
        fontStyle: this.isHovered ? 'bold' : 'normal',
        offsetX: 60,
        offsetY: 19,
      };
    },
  },
  mounted() {
    this.uiElement = this.$refs.transition.getNode();
  },
  methods: {
    handleIconClick(){
      typeof this.transition.startNode.ui.transitionEvents !== 'object' ? this.onRemoveClick() : this.onConfigClick()
    },
    getNodeById(id){
      return this.$store.state.automation.nodes[id]
    },
    calculateTextDirection() {
      // Find the perpendicular angle relative to the angle that the line is drawn at
      // This is used to find the position of the text at the distance provided above
      let textDirection = this.degrees;
      textDirection += 90;
      if(textDirection > 180)
      {
        textDirection -= 360;
      }

      return textDirection;
    },
    getTransitionPointsForVisibleLine() {
      const startNode = this.$store.state.automation.nodes[this.transition.startNode.nuid];
      const targetNode = this.$store.state.automation.nodes[this.transition.targetNode.nuid];
      const startPos = getOuterPositionForTransition(targetNode.position, startNode.position, 0);
      const targetPos = getOuterPositionForTransition(startNode.position, targetNode.position, this.transitionTargetOffset + this.pointerLength);
      return {
        startPos: {
          x:startPos.x + 92,
          y:startPos.y + 60,
        },
        targetPos: {
          x: targetPos.x + 92,
          y: targetPos.y + 60
        }
      };
    },
    setCursorPointer(e) {
      e.target.getStage().container().style.cursor = 'pointer';
    },
    setCursorDefault(e) {
      e.target.getStage().container().style.cursor = 'default';
    },
    onMouseEnter() {
      this.isHovered = true;
    },
    onMouseLeave() {
      this.isHovered = false;
    },
    onRemoveClick() {
      this.$store.dispatch('automation/deleteTransition', {tuid: this.transition.tuid, nuid: this.transition.startNode.nuid});
    },
    onConfigClick(){
      this.$emit('openConfig', this.transition.tuid)
    },
    onRemoveIconEnter(e) {
      this.isRemoveIconHovered = true;
      this.setCursorPointer(e);
    },
    onRemoveIconLeave(e) {
      this.isRemoveIconHovered = false;
      this.setCursorDefault(e);
    },
  },
}
</script>
