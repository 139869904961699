<template>
  <div ref="sidebar" class="automationSidebar">
    <div class="sidebar-header">
      <p class="sidebar-header__title" @click="changeCampaignNameModal = true">
        {{ campaignName }}
      </p>
      <v-row class="pa-0">
        <v-col v-if="!readOnly" cols="6" class="pr-1 pt-0">
          <v-btn
            class="custom-button custom-button--gray"
            height="34px"
            depressed
            block
            @click="$emit('saveDraft')"
          >
            Save Draft
          </v-btn>
        </v-col>
        <v-col
          :cols="readOnly ? 12 : 6"
          class="pt-0"
          :class="readOnly ? '' : 'pl-1'"
        >
          <v-btn
            class="custom-button"
            :class="isShowCounts.class"
            height="34px"
            depressed
            block
            @click="$emit('showCounts')"
          >
            {{ isShowCounts.text }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col cols="12" class="py-0">
          <v-btn
            v-if="!readOnly"
            class="custom-button custom-button--blue"
            height="34px"
            depressed
            block
            @click="$emit('save')"
          >
            Schedule Campaign
            <v-icon class="pl-1" size="15">
              mdi-arrow-right
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            class="custom-button custom-button--blue"
            height="34px"
            depressed
            block
            @click="showReport"
          >
            Show Reports
            <v-icon class="pl-1" size="15">
              mdi-chart-arc
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <section v-if="readOnly">
      <div class="header">
        Campaign
      </div>
      <div class="field__subheader pl-6 mb-2 pt-1">
        <span class="font-weight-bold black--text">Status: </span>
        {{ campaign.status }}
      </div>
      <div class="field__subheader pl-6 mb-2 pt-1">
        <span class="font-weight-bold black--text">Scheduled: </span>
        {{ campaign.displayStartDate }}
      </div>
    </section>
    <section v-if="readOnly">
      <div class="header">
        Statistics
      </div>
      <div class="field__subheader pl-6 mb-2 pt-1">
        <span class="font-weight-bold black--text">Delivered: </span>
        {{ getDeliveredPercentage(campaign) }}
      </div>
      <div class="field__subheader pl-6 mb-2 pt-1">
        <span class="font-weight-bold black--text">Opens: </span>
        {{ getOpensPercentage(campaign) }}
      </div>
      <div class="field__subheader pl-6 mb-2 pt-1">
        <span class="font-weight-bold black--text">Clicks: </span>
        {{ getClicksPercentage(campaign) }}
      </div>
      <div class="field__subheader pl-6 mb-2 pt-1">
        <span class="font-weight-bold black--text">Unsubscribes: </span>
        {{ getUnsubscribesPercentage(campaign) }}
      </div>
    </section>
    <section v-if="!readOnly">
      <div class="header">
        Triggers
      </div>
      <drag
        v-for="(node, index) in triggers"
        :key="'trigger-' + node.node_type"
        @dragstart="onDragStart(Object.assign({ index }, node), ...arguments)"
        @drag="onDragging"
        @dragend="onDragEnd"
      >
        <div class="node">
          <div
            class="icon"
            :style="{ backgroundColor: getNodeColor(node.node_type) }"
          >
            <img :src="node.ui.icons[0]">
          </div>
          <div class="label">
            {{ node.ui.label }}
          </div>
        </div>
        <template slot="image">
          <div class="node collapsed">
            <div
              class="icon"
              :style="{ backgroundColor: getNodeColor(node.node_type) }"
            >
              <img :src="node.ui.icons[0]" style="max-width: 18px; max-height: 18px;">
            </div>
          </div>
        </template>
      </drag>
    </section>
    <section v-if="!readOnly">
      <div class="header">
        Actions
      </div>
      <drag
        v-for="(node, index) in actions"
        :key="'trigger-' + node.node_type"
        @dragstart="onDragStart(Object.assign({ index }, node), ...arguments)"
        @drag="onDragging"
        @dragend="onDragEnd"
      >
        <div class="node">
          <div
            class="icon"
            :style="{ backgroundColor: getNodeColor(node.node_type) }"
          >
            <img :src="node.ui.icons[0]" style="max-width: 18px; max-height: 18px;">
          </div>
          <div class="label">
            {{ node.ui.label }}
          </div>
        </div>
        <template slot="image">
          <div class="node collapsed">
            <div
              class="icon"
              :style="{ backgroundColor: getNodeColor(node.node_type) }"
            >
              <img :src="node.ui.icons[0]">
            </div>
          </div>
        </template>
      </drag>
    </section>
    <section v-if="!readOnly">
      <div class="header">
        Conditions
      </div>
      <drag
        v-for="(node, index) in conditions"
        :key="'trigger-' + node.node_type"
        @dragstart="onDragStart(Object.assign({ index }, node), ...arguments)"
        @drag="onDragging"
        @dragend="onDragEnd"
      >
        <div class="node">
          <div
            class="icon"
            :style="{ backgroundColor: getNodeColor(node.node_type) }"
          >
            <img :src="node.ui.icons[0]" style="max-width: 18px; max-height: 18px;">
          </div>
          <div class="label">
            {{ node.ui.label }}
          </div>
        </div>
        <template slot="image">
          <div class="node collapsed">
            <div
              class="icon"
              :style="{ backgroundColor: getNodeColor(node.node_type) }"
            >
              <img :src="node.ui.icons[0]">
            </div>
          </div>
        </template>
      </drag>
    </section>

    <ChangeCampaignNameDialog
      :id="id"
      :dialog-state="changeCampaignNameModal"
      :campaign-name="campaignName"
      @close="changeCampaignNameModal = false"
      @done="campaignName = $event"
    />
  </div>
</template>

<script>
import { Drag } from "vue-drag-drop";
import Campaigns from "@/store/api/Campaigns";
import CampaignMixin from "@/mixins/campaign-mixin";
import ChangeCampaignNameDialog from "@/views/Campaigns/components/ChangeCampaignNameDialog";

const nodesByType = Campaigns.getNodeTemplatesByType();

export default {
  name: "AutomationSidebar",
  components: {
    Drag,
    ChangeCampaignNameDialog,
  },
  mixins: [CampaignMixin],
  props: {
    id: {
      default: () => false,
    },
    status: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      changeCampaignNameModal: false,
      cloneOfNodeBeingDragged: null,
    };
  },
  computed: {
    triggers() {
      return this.filterNodes(nodesByType.Trigger);
    },
    actions() {
      return this.filterNodes(nodesByType.Action);
    },
    conditions() {
      return this.filterNodes(nodesByType.Condition);
    },
    isShowCounts() {
      const isShow = this.$store.state.automation.isShowCounts;
      return {
        text: isShow ? "Hide Counts" : this.status === "draft" ? "Estimate Counts" : "Show Counts",
        class: isShow ? "custom-button--blue" : "custom-button--gray",
        value: isShow,
      };
    },
    campaignName: {
      get() {
        return (
          this.$store.getters["automation/getEditingCampaignName"] ??
          `Campaign #${this.id}`
        );
      },
      set(newValue) {
        const campaign = this.$store.getters["automation/getEditingCampaign"];
        this.$store.commit("automation/setCampaignBeingEdited", {
          ...campaign,
          name: newValue,
        });
      },
    },
  },
  methods: {
    filterNodes(allNodes) {
      const nodes = {};

      for (let key in allNodes) {
        const nodeType = allNodes[key];

        if (nodeType && nodeType.isVisible) {
          nodes[key] = nodeType;
        }
      }

      return nodes
    },
    getNodeColor(nodeType) {
      switch (nodeType) {
        case "Segment":
          return "rgba(32, 70, 115, 0.12)";
        case "AddContactToSegment":
          return "rgba(56, 126, 94, 0.2)";
        case "RemoveContactFromSegment":
          return "rgba(173, 45, 37, 0.2)";
        case "Eject":
          return "rgba(198, 198, 198, 0.32)";
        case "CheckEmailStatus":
          return "rgba(242, 210, 91, 0.48)";
        case "IsInSegment":
          return "rgba(230, 105, 73, 0.2)";
        case "CheckField":
          return "rgba(224, 125, 122, 0.2)";
        case "AddDelay":
          return "rgba(53, 53, 53, 0.2)";
        case "AutoResendEmail":
          return "rgba(78, 152, 247, 0.29)";
        case "SendDirectMail":
          return "rgba(153, 106, 192, 0.29)";
        case "SendEmail":
          return "rgba(91, 191, 215, 0.29)";
        case "SendSms":
          return "rgba(195, 245, 221, 0.8)";
        case "EmailSplitTest":
          return "rgba(127, 182, 249, 0.32)";
        default:
          return "rgba(127, 182, 249, 0.32)";
      }
    },
    onDragStart(nodeDetails, transferData, nativeEvent) {
      const canvasOffsetX =
        document.getElementsByClassName("automation")[0].offsetLeft;
      const canvasOffsetY =
        document.getElementsByClassName("automation")[0].offsetTop;

      nodeDetails.startPosition = {
        x: nativeEvent.clientX - canvasOffsetX - 285,
        y: nativeEvent.clientY - canvasOffsetY - 120,
      };

      this.$store.dispatch("automation/startCreatingNode", nodeDetails);
    },
    onDragging(transferData, nativeEvent) {
      const canvasOffsetX =
        document.getElementsByClassName("automation")[0].offsetLeft;
      const canvasOffsetY =
        document.getElementsByClassName("automation")[0].offsetTop;

      this.$store.dispatch("automation/draggedNodeBeingCreatedToPosition", {
        x: nativeEvent.clientX - canvasOffsetX - 250,
        y: nativeEvent.clientY - canvasOffsetY - 105,
      });
    },
    onDragEnd() {
      this.$store.dispatch("automation/stopCreatingNode");
    },
    getDeliveredCount(statistics) {
      return (statistics?.sends ?? 0) - (statistics?.hardBounces ?? 0) - (statistics?.softBounces ?? 0);
    },
    getDeliveredPercentage(item) {
      if (!item.statistics?.email?.sends) {
        return '-';
      }

      if (item.statistics.email.sends === 0) {
        return '0%';
      }

      return (
        (this.getDeliveredCount(item.statistics.email) / item.statistics.email.sends) *
        100
      ).toFixed(2) + '%';
    },
    getPercentageForValue(item, value) {
      if (value === undefined) {
        return '-';
      }

      const deliveredCount = this.getDeliveredCount(item.statistics.email);

      if (deliveredCount === 0) {
        return '0%';
      }

      return ((value / deliveredCount) * 100).toFixed(2) + '%';
    },
    getOpensPercentage(item) {
      return this.getPercentageForValue(item, item.statistics?.email?.uniqueOpens);
    },
    getClicksPercentage(item) {
      return this.getPercentageForValue(item, item.statistics?.email?.uniqueClicks);
    },
    getUnsubscribesPercentage(item) {
      return this.getPercentageForValue(item, item.statistics?.email?.unsubscribes);
    },
    showReport() {
      this.$router.push({ name: "EmailReport", query: { selectedCampaignIDs: [this.id] } });
    },
  },
};
</script>

<style lang="scss" scoped>
.automationSidebar {
  max-height: 100%;
  overflow-y: scroll;
  background-color: white;
  position: absolute;
  right: 0;
  width: 287px;
  top: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
  .sortable-fallback {
    opacity: 1;
  }

  .sidebar-header {
    padding: 23px 18px 15px;

    &__title {
      font-family: PlayfairDisplay;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #241c15;
      margin-bottom: 23px;
      line-height: 1.25;

      &:hover {
        text-decoration: underline;
        color: #2196f3;
        cursor: pointer;
      }
    }
  }

  section {
    .header {
      padding-left: 18px;
      display: flex;
      align-items: center;
      background-color: #f7f7f7;
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #66788e;
      border-bottom: solid 1px #dee7ee;
      border-top: solid 1px #dee7ee;
      height: 52px;
    }

    > div:not(.header):not(:last-child) {
      border-bottom: solid 1px #dee7ee;
    }

    ::v-deep .node {
      height: 56px;
      cursor: grab;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 18px;
      &.sortable-ghost {
        opacity: 1;
      }
      &.collapsed {
        display: inline-block;

        .label {
          display: none;
          width: 0;
        }
      }
      .icon {
        color: #fff;
        text-align: center;
        width: 27px;
        height: 27px;
        border-radius: 5px;
        padding-top: 3px;

        i {
          font-size: 1.25rem;
        }
      }
      .label {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #241c15;
        padding: 0 0.75em;
        overflow: hidden;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        transition: background-color 0.2s;
        width: 77%;
      }
      &:hover {
        background: rgba(225, 225, 225, 0.7);
      }
    }
  }
}
</style>
