<template>
  <v-group
    ref="node"
    :config="nodeConfig"
    @dragstart="onDragStart"
    @dragmove="onDragging"
    @dragend="onDragEnd"
    @click="onSelect"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <v-rect
      :config="parentConfig"
      @dblclick="openConfiguration"
    />
    <v-image
      :config="removeIconConfig"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="onRemoveClick"
    />
    <v-group
      v-if="!isHovered"
      :config="detailsConfig"
    >
      <v-text :config="nodeNameConfig" />
      <v-text :config="dbNodeConfig" />
      <div v-if="!isShowCounts">
        <v-text :config="nodeLabelConfig" />
      </div>
      <div v-else>
        <v-text :config="nodeCountEnteredLabelConfig" />
        <v-text :config="nodeCountProcessingLabelConfig" />
        <v-text :config="nodeCountCompletedLabelConfig" />
        <v-text :config="nodeCountEndedLabelConfig" />
      </div>
    </v-group>
    <NodeHoverConfiguration v-else :has_preview="node.config.creativeId" @onPreview="togglePreview" @onConfig="openConfiguration" @onDelete="onRemoveClick" />
    <v-group
      v-if="!readOnly && !isShowCounts"
      @mouseenter="onTransitionBarHover"
      @mouseleave="onTransitionBarLeave"
    >
      <v-rect ref="transitionBar" :config="transitionBarConfig" />
      <v-text :config="transitionTextConfig" />
    </v-group>
    <NodeStatistics :node="node" />
  </v-group>
</template>

<script>
import CampaignNodeMixin from "@/mixins/campaign-node-mixin";
import CampaignMixin from "@/mixins/campaign-mixin";
import NodeStatistics from "./NodeStatistics";
import NodeHoverConfiguration from "@/views/Campaigns/components/automation/NodeHoverConfiguration";

export default {
  name: "ActionSendSplitTestEmailNode",
  components: {NodeHoverConfiguration, NodeStatistics},
  mixins: [CampaignNodeMixin, CampaignMixin],
  data() {
    return {
      transitionBarColor: '#538ac1',
      creative: null,
      defaultTransitionHoverEvent: 'sent',
    };
  },
  computed: {
    leftPreviewControlConfig() {
      return {
          fill: '#aaa',
          fontFamily: 'FontAwesome',
          fontSize: 15,
          name: 'leftPreviewControl',
          offsetX: -145,
          offsetY: 90,
          text: "\uF053",
          transformsEnabled: 'position',
          visible: this.isPreviewVisible && this.node.config.testType === 'content',
      };
    },

    previewImageBackgroundConfig() {
      if(this.node.config.testType === 'subject')
      {
        return {
          fill: '#FFF',
          height: 180,
          name: 'previewImageBackground',
          offsetX: -135,
          offsetY: 90,
          shadowBlur: 5,
          width: 130,
          visible: this.isPreviewVisible
        };
      }
      else
      {
        return {
          fill: '#FFF',
          height: 200,
          name: 'previewImageBackground',
          offsetX: -135,
          offsetY: 100,
          shadowBlur: 5,
          width: 130,
          visible: this.isPreviewVisible
        };
      }
    },
    previewImageConfig() {
      if(this.previewImageSource)
      {
        const imageObj = new Image();
        let offsetY = 80;
        if(this.node.config.testType === 'subject')
        {
          imageObj.src = this.previewImageSource;
        }
        else
        {
          imageObj.src = this.previewImageSource;
          offsetY = 70;
        }

        const previewShown = this.node.ui.previewImageShown;

        return {
          height: 160,
          image: imageObj,
          name: 'previewImage',
          offsetX: -145,
          offsetY,
          width: 110 * (1/((previewShown || 0) + 1)),
          visible: this.isPreviewVisible,
        };
      }

      return {};
    },

    previewImageSource() {
      return '/img/demo-creative-thumb.jpg';
    },

    primaryIconConfig() {
      return {
        fill: '#FFF',
        fontFamily: 'FontAwesome',
        fontSize: 35,
        listening: false,
        name: 'primaryIcon',
        offsetX: 93,
        offsetY: 20,
        text: "\uF126",
        transformsEnabled: 'position',
        visible: true,
      };
    },

    previewTestLabelConfig() {
      return {
        fill: '#1d9ed3',
        fontSize: 15,
        fontStyle: 'bold',
        listening: false,
        name: 'rightPreviewControl',
        offsetX: -195,
        offsetY: 90,
        text: 'ABC'[this.node.ui.previewImageShown || 0],
        transformsEnabled: 'position',
        visible: this.isPreviewVisible && this.node.config.testType === 'content',
      };
    },

    rightPreviewControlConfig() {
      return {
        fill: '#aaa',
        fontFamily: 'FontAwesome',
        fontSize: 15,
        name: 'rightPreviewControl',
        offsetX: -245,
        offsetY: 90,
        text: "\uF054",
        transformsEnabled: 'position',
        visible: this.isPreviewVisible && this.node.config.testType === 'content',
      };
    },
  },
  methods: {
    onPreviousControlClick() {
      let previewIndex = this.node.ui.previewImageShown || 0;
      if(previewIndex - 1 < 0)
      {
        previewIndex = 0;
      }
      else
      {
        previewIndex--;
      }

      this.$store.dispatch('automation/setPreviewShownForNode', {
        nuid: this.node.nuid,
        previewIndex,
      });
    },
    onNextControlClick() {
      let previewIndex = this.node.ui.previewImageShown || 0;
      if(previewIndex + 1 === this.node.config.type.length)
      {
        previewIndex = 0;
      }
      else
      {
        previewIndex++;
      }

      this.$store.dispatch('automation/setPreviewShownForNode', {
        nuid: this.node.nuid,
        previewIndex,
      });
    },
    toggleSplitTestPreview() {
      this.$store.dispatch('automation/setPreviewShownForNode', {
        nuid: this.node.nuid,
        previewIndex: 0,
      });

      this.togglePreview();
    },
    async togglePreview() {
      if (this.creative) {
        this.$store.dispatch("automation/openPreviewEmail", this.creative);
        return;
      }

      try {
        const { data: creative } = await this.$rest.creative.email.get_resource(this.node.config.creativeId);

        this.creative = creative;
        creative.urls = creative.imageFileUrls;
        this.$store.dispatch("automation/openPreviewEmail", creative);
      } catch (e) {
        this.$store.commit("snackbar/showMessage", {
          color: "error",
          content: "Retrieve email creative failed",
        });
      }
    }
  },
}
</script>
