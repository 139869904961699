<template>
  <v-card class="pb-7 pt-4 px-7">
    <v-card-text class="pa-0" style="overflow-x: hidden">
      <PageHeader>
        <template #subheader>
          Select Existing Email Message
        </template>
      </PageHeader>

      <SearchFieldRowWithPagination
        search-field-placeholder="Search for creatives"
        :current-page-number="creatives_page_number"
        :current-page-size="creatives_page_size"
        :count-total="creatives_total"
        :page-sizes="[25, 50, 100]"
        @next-page="get_creatives_next_page"
        @prev-page="get_creatives_prev_page"
        @change-page-size="creatives_change_page_size"
        @search="(search_string) => on_search(search_string)"
      />

      <div class="campaigns-dialog-table">
        <v-simple-table>
          <thead>
            <tr class="campaigns-table__header">
              <th>Content</th>
              <th>Name</th>
              <th>Dates</th>
            </tr>
          </thead>
          <tbody class="search-table__content">
            <tr
              v-for="(item, i) in creatives"
              :key="i"
              :class="{ 'selected-row': item.id === selected_creative.id }"
              @click="selected_creative = item"
            >
              <td style="max-width: 132px">
                <v-img
                  max-height="95px"
                  contain
                  class="my-4"
                  :src="item.imageFileUrls.small"
                />
              </td>
              <td class="py-1">
                <div class="campaigns-table__campaign-header">
                  {{ item.name }}
                </div>
                <small class="text--disabled" style="font-size: 11px;">
                  ID: {{ item.id }}
                </small>
              </td>
              <td class="py-1">
                <div class="campaigns-table__campaign-subheader">
                  <div class="pt-1">
                    <span class="font-weight-bold black--text">Created: </span>
                    {{ new Date(item.createdAt).toDateString() }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex justify-end align-center pa-0 mx-3 mt-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        :disabled="!selected_creative.id"
        @click="on_select"
      >
        Select
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";

export default {
  name: "ChooseExistingCreativeDialog",
  components: {
    SearchFieldRowWithPagination,
    PageHeader,
  },
  props: {
    showArchived: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      creatives: [],
      creatives_total: 0,
      creatives_page_number: 1,
      creatives_page_size: 25,
      search_string: null,
      selected_creative: {},
    };
  },
  created() {
    this.get_available_creatives();
  },
  methods: {
    on_select() {
      this.$emit("dismiss");
      this.$emit("select", this.selected_creative);
    },
    async on_search(search_string) {
      this.search_string = search_string;
      await this.get_available_creatives();
    },

    async creatives_change_page_size(size) {
      this.creatives_page_size = size;
      this.creatives_page_number = 1;
      await this.get_available_creatives();
    },
    async get_creatives_next_page() {
      this.creatives_page_number += 1;
      await this.get_available_creatives();
    },
    async get_creatives_prev_page() {
      this.creatives_page_number -= 1;
      await this.get_available_creatives();
    },
    async get_available_creatives() {
      const resp = await this.$rest.creative.email.get_collection({
        limit: this.creatives_page_size,
        page: this.creatives_page_number,
        name: this.search_string || null,
        isArchived: this.showArchived ? null : false,
        htmlContentExist: true,
        sort: ['createdAt:desc'],
      });
      this.creatives = resp.data.items.map(item => ({
        ...item,
        name: item.isArchived ? item.name + " [Archived]" : item.name,
      }));
      this.creatives_total = resp.data.totalCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-dialog-table {
  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    tr {
      height: 120px;
    }
    tr > td {
      border-bottom: solid 1px #dee7ee;
    }
    tr:hover {
      cursor: pointer;
    }
  }

  .selected-row {
    background: #e8e8e8;
    border: 1px solid #2b84eb;
  }
}
</style>
