export default {
  computed: {
    campaign() {
      return this.$store.getters['automation/getEditingCampaign'];
    },
    readOnly() {
      return ['preparing','live','completed','canceled'].includes(this.campaign?.status)
    },
  },
};
