<template>
  <v-row class="animated fadeIn">
    <div
      ref="automation"
      class="automation"
    >
      <v-alert
        v-if="accountHasTopicOptOut"
        color="#f8e8ad"
        icon="mdi-alert"
        class="mt-3 mb-0"
        style="margin-right: 287px;"
      >
        Attention: Your account is configured with specific topic/segment opt-ins.
        Please exercise caution when selecting segments for your campaigns.
        Ensure that the segments you choose align with your current opt-ins to avoid targeting errors and to maintain compliance with audience preferences.
      </v-alert>
      <drop
        @drop="onDrop"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
      >
        <v-stage
          v-if="!isLoadingSegments"
          ref="stage"
          :config="stageConfig"
          @dragend="onDragEnd"
          @mousemove="onMouseMove"
          @mouseup="onMouseUp"
          @mouseleave="onMouseLeave"
          @wheel="zoom"
          @click="onClick"
        >
          <v-layer
            ref="mainLayer"
            :config="mainLayerConfig"
          >
            <SnapGuide type="vertical" />
            <SnapGuide type="horizontal" />
            <CampaignNode
              v-for="node in nodesForMainLayer"
              :key="node.nuid"
              :node="node"
              :segments="segments"
            />
            <Transition
              v-for="transition in transitionsForMainLayer"
              :key="transition.tuid"
              :transition="transition"
              @openConfig="openTransitionConfig"
            />
          </v-layer>
          <v-layer
            ref="actionLayer"
            :config="actionLayerConfig"
          >
            <v-arrow ref="transitionBeingCreated" :config="transitionBeingCreatedConfig" />
            <CampaignNode
              v-for="node in nodesForActionLayer"
              :key="node.nuid"
              :node="node"
              :segments="segments"
            />
            <Transition
              v-for="transition in transitionsForActionLayer"
              :key="transition.tuid"
              :transition="transition"
              @openConfig="openTransitionConfig"
            />
          </v-layer>
        </v-stage>
      </drop>

      <v-btn
        class="custom-button custom-button--gray snapshot-button"
        height="34px"
        width="150px"
        depressed
        @click="downloadURI"
      >
        Take Snapshot
      </v-btn>

      <div class="zoom-buttons d-flex">
        <v-btn
          class="custom-button custom-button--gray mr-2"
          height="35px"
          style="min-width: 35px; width: 35px"
          depressed
          @click="$store.dispatch('automation/zoom', false);"
        >
          <v-icon size="18">
            mdi-minus
          </v-icon>
        </v-btn>
        <v-btn
          class="custom-button custom-button--gray"
          height="35px"
          style="min-width: 35px; width: 35px"
          depressed
          @click="$store.dispatch('automation/zoom', true);"
        >
          <v-icon size="18">
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
      <!--<AutomationToolbar />-->
      <AutomationSidebar
        :id="id"
        :status="status"
        @save="saveCampaign"
        @saveDraft="saveCampaign(true)"
        @showCounts="showCounts"
      />
      <AutomationNodeConfigurationModal
        :node="nodeBeingConfigured"
        :segments="segments"
        @close="stopConfiguringNode"
      />
      <v-dialog v-model="select_transition_event_dialog" scrollable max-width="315px">
        <SelectTransitionEventDialog
          v-if="select_transition_event_dialog"
          :transition="edited_transition"
          @save="changeTransitionEvent(edited_transition, $event)"
          @dismiss="closeTransitionConfigPopup"
        />
      </v-dialog>
      <v-dialog v-model="isShowingPreviewEmail" scrollable max-width="800px">
        <PreviewCreativeDialog
          v-if="isShowingPreviewEmail"
          :creative="emailCreative"
          @dismiss="$store.dispatch('automation/closePreviewEmail')"
        />
      </v-dialog>
    </div>
  </v-row>
</template>

<script>
import AutomationSidebar from "./components/automation/AutomationSidebar";
import CampaignNode from "./components/automation/CampaignNode";
import SnapGuide from "./components/automation/SnapGuide";
import Transition from "./components/automation/Transition";
import { EventBus } from "@/sharedComponents/event-bus";
import { Drop } from "vue-drag-drop";
import AutomationNodeConfigurationModal from "./components/automation/AutomationNodeConfigurationModal";
import SelectTransitionEventDialog from "@/views/Campaigns/components/automation/SelectTransitionEventDialog";
import PreviewCreativeDialog from "@/views/Contacts/components/DigitalPersona/PreviewCreativeDialog";
import _ from "lodash";
import Campaigns, { checkIfValid, getPreviousEmailNodes } from "@/store/api/Campaigns";
import CampaignMixin from "@/mixins/campaign-mixin";

let stageWidth = window.innerWidth - 192;
let stageHeight = window.innerHeight - 60;
const nodesByType = Campaigns.getNodeTemplatesByType();

export default {
  name: 'AutomationCampaign',
  components: {
    PreviewCreativeDialog,
    SelectTransitionEventDialog,
    AutomationNodeConfigurationModal,
    AutomationSidebar,
    CampaignNode,
    Drop,
    SnapGuide,
    Transition,
  },
  mixins: [CampaignMixin],
  beforeRouteLeave(to, from, next) {
    if(to?.name !== "ScheduleCampaign") {
      this.$store.commit('automation/setNodes', {});
      this.$store.commit('automation/setFlushTransitions', {});
    }

    next();
  },
  props: {
    id: {
      default: false,
      required: true,
    }
  },
  data() {
    return {
      select_transition_event_dialog: false,
      edited_transition: '',
      enteredWithSidebarMinimized: false,
      gridLayerConfig: {
        name: 'gridLayer',
      },
      gridSpacing: 20,
      isLoadingSegments: false,
      segments: [],
    };
  },
  computed: {
    accountHasTopicOptOut() {
      return this.$store.getters["user/accountHasTopicOptOut"];
    },
    status() {
      return this.campaign?.status ?? "draft";
    },
    isShowingPreviewEmail: {
      get() {
        return this.$store.getters["automation/getIsPreviewingEmail"];
      },
      set() {
        this.$store.dispatch("automation/closePreviewEmail");
      },
    },
    emailCreative() {
      return this.$store.getters["automation/getEmailCreative"];
    },
    actionLayerConfig() {
      const scale = this.$store.state.automation.scale;
      return {
        name: 'actionLayer',
        scale: {
          x: scale,
          y: scale,
        },
      };
    },
    mainLayerConfig() {
      const scale = this.$store.state.automation.scale;
      return {
        name: 'mainLayer',
        scale: {
          x: scale,
          y: scale,
        },
      };
    },
    nodeBeingConfigured() {
      return this.$store.state.automation.nodeBeingConfigured;
    },
    nodesForMainLayer() {
      return this.$store.getters['automation/getNodesForMainLayer'];
    },
    nodesForActionLayer() {
      return this.$store.getters['automation/getNodesForActionLayer'];
    },
    stageConfig() {
      return {
        draggable: true,
        height: this.$store.state.automation.containerHeight || stageHeight,
        name: 'stage',
        stroke: 'black',
        strokeWidth: 4,
        transformsEnabled: 'position',
        width: this.$store.getters['automation/getStageWidth'] || stageWidth,
      };
    },
    transitionBeingCreatedConfig() {
      return {
        fill: '#000',
        hitStrokeWidth: 0,
        name: 'transitionBeingCreated',
        points: this.$store.getters['automation/getPointsForTransitionBeingCreated'],
        pointerLength: 10,
        pointerWidth: 12,
        shadowForStrokeEnabled: false,
        stroke: '#000',
        strokeWidth: 2,
        visible: !!this.$store.state.automation.transitionBeingCreated,
      };
    },
    transitionsForMainLayer() {
      return this.$store.getters['automation/getTransitionsForMainLayer'];
    },
    transitionsForActionLayer() {
      return this.$store.getters['automation/getTransitionsForActionLayer'];
    },
    nodesListToSave() {
      const data_to_save = {};
      const nodes = this.$store.getters['automation/getNodes'];

      Object.values(nodes).forEach(node => {
        data_to_save[node.nuid] = {
          "type": node.type,
          "nodeType": node.node_type,
          "isValid": node.isValid,
          "name": node.config.label,
          "position": {...node.position},
          "config": { ...node.config},
          "transitions": {
            ...node.transitions
          }
        };
      })
      return data_to_save;
    },
  },
  async mounted() {
    await this.$nextTick(() => this.fitStageIntoParentContainer());
    window.addEventListener('resize', this.fitStageIntoParentContainer);

    await this.loadCampaign(this.$route.params.id || null);
    await this.loadSegments();
    this.$store.commit("automation/toggleCounts", false);

    // force update v-stage container to load the segment images correctly on the campaign nodes
    this.isLoadingSegments = true;
    setTimeout(() => {
      this.isLoadingSegments = false;
    }, 100);
  },
  beforeDestroy() {
    if (!this.enteredWithSidebarMinimized) {
      document.getElementsByTagName('body')[0].classList.remove('sidebar-minimized');
    }

    document.getElementsByTagName('body')[0].classList.remove('automation-view');
    window.removeEventListener('resize', this.fitStageIntoParentContainer);
    this.$refs.stage.getStage().destroy();
  },
  beforeCreate() {
    //clear transitions
    this.$store.commit('automation/setFlushTransitions', {});
  },
  methods: {
    async loadSegments() {
      this.isLoadingSegments = true;

      return this.$store
        .dispatch("automation/loadConfigurationData", { key: "segments", campaignStatus: this.status })
        .then((segments) => {
          this.isLoadingSegments = false;
          this.segments = segments || [];
        });
    },
    downloadURI() {
      const url = this.$refs.stage.getStage().toDataURL({ pixelRatio: 3 });
      const link = document.createElement('a');
      link.download = `AutomatedCampaign-${this.id}`;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeTransitionConfigPopup(){
      this.select_transition_event_dialog = false;
      this.edited_transition = false;
    },
    changeTransitionEvent(transition, event){
      this.$store.commit('automation/updateTransition', {transition, event});
      this.select_transition_event_dialog = false;
      this.edited_transition = false;
    },
    openTransitionConfig(transitionId){
      this.edited_transition = transitionId;
      this.select_transition_event_dialog = true;
    },
    async estimateCounts() {
      let nodesForMainLayer = {};
      let nodesForEstimate = Object.keys(this.$store.getters['automation/getNodes'])
          .reduce((obj, key) => {
            obj[key] = this.$store.getters['automation/getNodes'][key];
            return obj;
          }, {});

      if (!this.readOnly) {
        const estimate_counts = await this.$rest.campaign.estimate_counts(this.nodesListToSave);
        nodesForMainLayer = _.merge(nodesForEstimate, estimate_counts.data.counts);
      } else {
        const nodes =  await this.$rest.campaign.get_nodes(this.id);
        const mappedObj = {};

        for (const node of nodes.data) {
          // only get list Node on screen
          if (this.nodesListToSave[node.uuid]) {
            mappedObj[node.uuid] = {
              entered: node.enteredCount,
              processing: node.enteredCount - (node.completedCount + node.failedCount),
              completed: node.completedCount,
              failed: node.failedCount,
            }
          }
        }

        nodesForMainLayer = _.merge(nodesForEstimate, mappedObj);
      }

      // update In/Out to nodesForMainLayer
      this.$store.commit('automation/setNodes', nodesForMainLayer);

    },
    async showCounts() {
      if (this.$store.state.automation.isShowCounts) {
        this.$store.commit("automation/toggleCounts");
        return;
      }
      await this.estimateCounts();
      this.$store.commit("automation/toggleCounts");
    },
    async saveCampaign(isDraft) {
      if (!isDraft && !this.validateCampaignForScheduled()) {
        return;
      }
      let offsetX = 0;
      let offsetY = 0;

      for (let nodeId in this.nodesListToSave) {
        if (offsetX === 0 || this.nodesListToSave[nodeId].position.x < offsetX) {
          offsetX = this.nodesListToSave[nodeId].position.x;
        }
        if (offsetY === 0 || this.nodesListToSave[nodeId].position.y < offsetY) {
          offsetY = this.nodesListToSave[nodeId].position.y;
        }
      }
      offsetX = (offsetX * -1) + 40;
      offsetY = (offsetY * -1) + 40;

      for (let nodeId in this.nodesListToSave) {
        this.nodesListToSave[nodeId].position.x += offsetX;
        this.nodesListToSave[nodeId].position.y += offsetY;
      }

      await this.$rest.campaign.put_resource(this.id, {
        draftConfiguration: {
          nodes: {...this.nodesListToSave}
        }
      });

      if (isDraft) {
        this.$notifier.success("Campaign draft was saved successfully");
      } else {
        await this.$router.push({name: 'ScheduleCampaign', props: {id: this.id}});
      }
    },
    reValuatedNodes() {
      for (let nodeId in this.nodesListToSave) {
        const transitions = this.$store.getters["automation/getTransitionsForMainLayer"];
        const filteredTransitions = Object.keys(transitions)
          .filter(key => transitions[key].targetNode.nuid === nodeId)
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: transitions[key]
            });
          }, {});
        const previousEmailNodes = getPreviousEmailNodes(this.nodesListToSave, filteredTransitions);
        if(previousEmailNodes[0]) {
          this.$store.dispatch("automation/updateNode", {
            isValid: checkIfValid(previousEmailNodes[0]),
            nuid: nodeId,
          });
        }
      }
    },
    validateCampaignForScheduled() {
      this.reValuatedNodes();
      // Validate all nodes have been configured correctly
      const errors = [];

      for (let nodeId in this.nodesListToSave) {
        if (!this.nodesListToSave[nodeId]) {
          continue;
        }

        const node = this.nodesListToSave[nodeId];
        if (node.isValid) {
          continue;
        }

        const nodeLabel = this.getNodeLabelForMessage(node);
        errors.push(nodeLabel + ' has not been fully configured.');

        if (this.isNodeOrphaned(nodeId, node)) {
          errors.push(nodeLabel + ' has not been connected to another node or requires connection to a Trigger.');
        }
      }

      if (errors.length > 0) {
        this.$notifier.error(errors.join("\n"));
        return false;
      }

      return true;
    },
    getNodeLabelForMessage(node) {
      let label = `A ${node.nodeType} ${node.type}`;
      if (node.name) {
        label = `The "${node.name}" ${node.type}`;
      } else {
        const nodeType = nodesByType[node.type][node.nodeType] ?? null;

        if (nodeType) {
          if (node.type === 'Trigger') {
            // Segment Trigger already has Trigger in the label so we should avoid duplicate "Trigger" in message
            label = `A ${nodeType.ui.label}`;
          } else {
            label = `A ${nodeType.ui.label} ${node.type}`;
          }
        }
      }

      return label;
    },
    isNodeOrphaned(nodeIdToCheck, nodeToCheck) {
      // If the node is a trigger, we need to check if it connects to another node. If not, it is orphaned
      if (nodeToCheck.type === 'Trigger') {
        // If the trigger has transitions so it is not orphaned
        return nodeToCheck.transitions.length > 0;
      }

      // Check to see if the node has been connected to by another node
      for (let nodeId in this.nodesListToSave) {
        if (nodeId === nodeIdToCheck
          || !this.nodesListToSave[nodeId]
          || this.nodesListToSave[nodeId].transitions.length === 0
        ) {
          continue;
        }

        for (let transactionId in this.nodesListToSave[nodeId].transitions) {
          if (!this.nodesListToSave[nodeId].transitions[transactionId]) {
            continue;
          }

          if (this.nodesListToSave[nodeId].transitions[transactionId].next === nodeIdToCheck) {
            return false;
          }
        }
      }

      // If it got here, it means that the node is not connected to by another node
      // Since the node is not a trigger, it is orphaned
      return true;
    },
    fitStageIntoParentContainer() {
      const container = this.$refs.automation;

      if (container === undefined) {
        return;
      }

      setTimeout(() => {
        this.$nextTick(() => {
          this.$store.dispatch('automation/resizeContainer', {
            width: container.offsetWidth,
            height: container.offsetHeight,
          });
        });
      }, 200);
    },
    getActionLayer() {
      return this.$refs.actionLayer.getNode();
    },
    getMainLayer() {
      return this.$refs.mainLayer.getNode();
    },
    getTransitionBeingCreated() {
      return this.$refs.transitionBeingCreated.getNode();
    },
    async loadCampaign(campaignId) {
      if (!campaignId) {
        await this.$store.dispatch('automation/startCampaign', 'New Automation Draft');
        return;
      }

      await this.$store.dispatch('automation/loadCampaignData', campaignId);
      await this.$forceUpdate();
    },
    onClick(e) {
      if (e.target.getName() === 'stage') {
        this.$store.dispatch('automation/unselectNode');
      }
    },
    onDragEnd(e) {
      this.$store.dispatch('automation/canvasMoved', {x: e.currentTarget.getX(), y: e.currentTarget.getY()});
    },
    onDragEnter() {
      this.$store.dispatch('automation/creatingNodeInCanvas');
    },
    onDragLeave() {
      this.$store.dispatch('automation/leavingCanvasWithCreatedNode');
    },
    onDrop() {
      this.$store.dispatch('automation/createNode');
    },
    onMouseLeave() {
      const transitionBeingCreated = this.$store.state.automation.transitionBeingCreated;
      if (transitionBeingCreated) {
        EventBus.$emit('transitionDone', transitionBeingCreated);

        this.$store.dispatch('automation/createTransition');
      }
    },
    onMouseMove(e) {
      const transitionBeingCreated = this.$store.state.automation.transitionBeingCreated;

      if (!!transitionBeingCreated && !transitionBeingCreated.targetNode) {
        this.$store.dispatch('automation/setTargetPositionForTransition', {x: e.evt.offsetX, y: e.evt.offsetY});
      }
    },
    onMouseUp() {
      if (!this.$store.state.automation.transitionBeingCreated) {
        return;
      }

      /* this.$notifier.success("Error here")*/
      this.$store.dispatch('automation/createTransition')
      .catch(error => {
        console.error(error);
        this.$notifier.error("Transition Error.");
      });
    },
    stopConfiguringNode() {
      this.$store.dispatch('automation/stopConfiguringNode');
      this.forceUpdateStage();
    },
    zoom(e) {
      const isZoomIn = e.evt.wheelDelta > 0;
      this.$store.dispatch('automation/zoom', isZoomIn);
    },
    forceUpdateStage() {
      // force update v-stage container to load the segment images correctly on the campaign nodes
      this.isLoadingSegments = true;
      setTimeout(() => {
        this.isLoadingSegments = false;
      }, 100);
    }
  },
}
</script>

<style lang="scss" scoped>
.row.animated{
  margin-left: 0;
  margin-right: 0;
}

.automation{
  background-color: #FFF;
  position: relative;
  width: 100%;
  background-image: url("~@/assets/rectangle.png");
  background-repeat: repeat;
  height: calc(100vh - 58px);
}

.snapshot-button {
  position: absolute;
  left: 28px;
  bottom: 25px;
}
.zoom-buttons {
  position: absolute;
  right: 315px;
  bottom: 25px;
}
</style>
