<template>
  <v-card class="custom-field-popup-card">
    <v-card-title class="px-5 pt-4 pb-4">
      <div>Select Business Rule</div>
    </v-card-title>
    <v-divider />
    <v-card-text class="pl-5 pr-2 pt-4 pb-1">
      <div
        v-for="(value, key) in events"
        :key="key"
        class="d-flex align-center justify-space-between mb-3"
      >
        <div>
          <div class="notification-menu__header mb-1">
            {{ value }}
          </div>
          <!--          <div class="field__subheader&#45;&#45;small">
            Some text here
          </div>-->
        </div>
        <v-checkbox
          v-model="selected_events[key]"
          :disabled="readOnly"
          class="custom-fields-row-checkbox mt-1 mr-1"
        />
      </div>
    </v-card-text>

    <v-card-actions
      class="divider-with-shadow d-flex justify-end px-6 py-2"
    >
      <v-btn
        color="warning"
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="75px"
        @click="$emit('dismiss')"
      >
        Close
      </v-btn>
      <v-btn
        v-if="!readOnly"
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="75px"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import CampaignMixin from "@/mixins/campaign-mixin";

export default {
  name: "SelectTransitionEventDialog",
  components: {},
  mixins: [CampaignMixin],
  props: {
    transition: {
      default: () => false,
    },
  },
  data() {
    return {
      selected_events: {},
      events: [],
      transitions: {},
      action_types: [

      ],
    };
  },
  created() {
    let transition = this.$store.state.automation.transitions[this.transition]
    this.transitions = this.getSameTransitions(transition.startNode.nuid, transition.targetNode.nuid)
    this.selected_events[transition.event] = true;
    this.events = {...transition.startNode.ui.transitionEvents}
    Object.values(this.transitions).forEach(e => this.selected_events[e.event] = true )
  },
  methods: {
    getSameTransitions(start, target){
      let temp = {};
      Object.entries(this.$store.state.automation.transitions).forEach(([key, value]) => {
        if (value.startNode.nuid === start && value.targetNode.nuid === target){
          temp[key] = this.$store.state.automation.transitions[key]
        }

      })
      return temp
    },
    save() {
      let same_transition = this.$store.state.automation.transitions[this.transition]
      this.transitions = this.getSameTransitions(same_transition.startNode.nuid, same_transition.targetNode.nuid)
      let startNode=same_transition.startNode;
      let targetNode = same_transition.targetNode;
      Object.keys(this.selected_events).forEach(ev => {
        let isTransitionExists = Object.values(this.transitions).filter(i => i.event === ev).length > 0
        let current_transition = isTransitionExists ? Object.values(this.transitions).filter(i => i.event === ev)[0] : undefined
        if (this.selected_events[ev] === true){
          //if transition doesn't exists but marked as true - create it
          if (!isTransitionExists){
            this.$store.commit("automation/createTransition", {startNode, targetNode, event: ev, tuid: uuidv4()} )
          }
        } else {
          //if transition exists but marked as false - delete it
          if (isTransitionExists){
            this.$store.dispatch("automation/deleteTransition", { tuid: current_transition.tuid, nuid: this.transitions[current_transition.tuid].startNode.nuid })
          }
        }
      })
      this.$emit("dismiss");
    },
  },
};
</script>
