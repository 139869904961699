<template>
  <div />
</template>

<script>
export default {
  name: "ConditionCheckFieldConfiguration",

  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
