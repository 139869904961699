<template>
  <v-group
    :config="statisticGroupConfig"
  >
    <v-rect :config="statisticBackgroundConfig" />
    <v-text :config="inLabelConfig" />
    <v-text :config="inStatisticConfig" />
    <v-line :config="separatorConfig" />
    <v-text :config="outLabelConfig" />
    <v-text :config="outStatisticConfig" />
  </v-group>
</template>

<script>
    export default {
        name: "NodeStatistics",
        props: {
            node: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                inLabelConfig: {
                    align: 'center',
                    fill: '#FFF',
                    fontSize: 20,
                    fontStyle: 'bold',
                    lineHeight: 1.1,
                    name: 'inLabel',
                    offsetX: 110,
                    offsetY: 20,
                    text: 'IN',
                    transformsEnabled: 'position',
                    width: 100,
                },
                outLabelConfig: {
                    align: 'center',
                    fill: '#FFF',
                    fontSize: 20,
                    fontStyle: 'bold',
                    lineHeight: 1.1,
                    name: 'inLabel',
                    offsetX: -10,
                    offsetY: 20,
                    text: 'OUT',
                    transformsEnabled: 'position',
                    width: 100,
                },
                separatorConfig: {
                    points: [0, 28, 0, -25],
                    stroke: '#aaa',
                },
                statisticBackgroundConfig: {
                    fill: '#000',
                    height: 120,
                    name: 'statisticBackground',
                    offsetX: 118,
                    offsetY: 60,
                    opacity: 0.8,
                    transformsEnabled: 'position',
                    width: 182,
                },
            };
        },
        computed: {
            statisticGroupConfig() {
                return {
                    name: 'statisticGroup',
                    visible: !!this.$store.state.automation.showNodeStatistics,
                };
            },
            inStatisticConfig() {
                return {
                    align: 'center',
                    fill: '#FFF',
                    fontSize: 20,
                    fontStyle: 'bold',
                    lineHeight: 1.1,
                    name: 'inStatistic',
                    offsetX: 110,
                    offsetY: -5,
                    text: (this.node.statistics.in || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    //text: '200,000',
                    transformsEnabled: 'position',
                    width: 100,
                };
            },
            outStatisticConfig() {
                return {
                    align: 'center',
                    fill: '#FFF',
                    fontSize: 20,
                    fontStyle: 'bold',
                    lineHeight: 1.1,
                    name: 'outStatistic',
                    offsetX: -10,
                    offsetY: -5,
                    text: (this.node.statistics.out || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    //text: '100,000',
                    transformsEnabled: 'position',
                    width: 100,
                };
            },
        },
    }
</script>
