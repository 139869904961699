<template>
  <v-group
    ref="node"
    :config="nodeConfig"
    @dragstart="onDragStart"
    @dragmove="onDragging"
    @dragend="onDragEnd"
    @click="onSelect"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <v-rect
      :config="parentConfig"
    />
    <v-image
      :config="removeIconConfig"
      @mouseenter="setCursorPointer"
      @mouseleave="setCursorDefault"
      @click="onRemoveClick"
    />
    <v-group
      v-if="!isHovered"
      :config="detailsConfig"
    >
      <v-text :config="nodeNameConfig" />
      <v-text :config="dbNodeConfig" />
      <div v-if="!isShowCounts">
        <v-text :config="nodeLabelConfig" />
      </div>
      <div v-else>
        <v-text :config="nodeCountEnteredLabelConfig" />
        <v-text :config="nodeCountProcessingLabelConfig" />
        <v-text :config="nodeCountCompletedLabelConfig" />
        <v-text :config="nodeCountEndedLabelConfig" />
      </div>
    </v-group>
    <NodeHoverConfiguration v-else @onPreview="togglePreview" @onConfig="openConfiguration" @onDelete="onRemoveClick" />
    <v-group
      v-if="!readOnly && !isShowCounts"
      @mouseenter="onTransitionBarHover"
      @mouseleave="onTransitionBarLeave"
    >
      <v-rect ref="transitionBar" :config="transitionBarConfig" />
      <v-text :config="transitionTextConfig" />
    </v-group>
  </v-group>
</template>

<script>
    import CampaignNodeMixin from "@/mixins/campaign-node-mixin";
    import CampaignMixin from "@/mixins/campaign-mixin";
    import MultipleTransitionsMixin from "@/mixins/multiple-transitions-mixin";
    import NodeHoverConfiguration from "@/views/Campaigns/components/automation/NodeHoverConfiguration";
    export default {
        name: "ActionAutoResendEmailNode",
        components: {NodeHoverConfiguration},
        mixins: [CampaignNodeMixin, MultipleTransitionsMixin, CampaignMixin],
        data() {
            return {
                transitionHoveredBorderColor: '#7fbcfb',
                transitionTextColor: '#414d58',
            };
        },
      computed: {
            primaryIconConfig() {
                return {
                    fill: '#FFF',
                    fontFamily: 'FontAwesome',
                    fontSize: 35,
                    listening: false,
                    name: 'primaryIcon',
                    offsetX: 93,
                    offsetY: 18,
                    text: "\uF064",
                    transformsEnabled: 'position',
                    visible: true,
                };
            },
        },
    }
</script>
