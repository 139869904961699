<template>
  <div>
    <template v-if="hasPreviousNode">
      <div class="field__header mb-1">
        Previous Email Action<span v-if="hasMoreThanOnePreviousNode">s</span>:
      </div>

      <div class="previous-nodes mb-3">
        <div
          v-for="(previousNode, index) in previousNodes"
          :key="'previous-node-' + previousNode.nuid"
          :style="{ backgroundColor: previousNode.ui.color }"
          class="previous-node"
        >
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header class="configuration-panel-header">
                <v-row>
                  <v-col cols="3" class="icon-group py-0 d-flex align-center">
                    <strong v-if="hasMoreThanOnePreviousNode" class="white--text">#{{ index + 1 }}
                    </strong>
                    <div
                      class="d-flex align-center justify-center flex-wrap"
                      style="width: 100%; height: 100%"
                    >
                      <img :src="previousNode.ui.icons[1]" alt="" width="30px" class="mx-2">
                    </div>
                  </v-col>
                  <v-col cols="8" class="py-0">
                    <div class="action-title my-2">
                      {{ previousNode.ui.label }} Action
                    </div>
                    <div class="action-label my-2">
                      {{ previousNode.config.name || "Not Configured" }}
                    </div>
                  </v-col>
                </v-row>
                <div slot="actions">
                  <v-icon color="white">
                    mdi-chevron-down
                  </v-icon>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="configuration-panel-content">
                  <template v-if="previousNode.node_type === 'SendEmail'">
                    <div>
                      <div class="action-label mb-2">
                        <strong>Subject: </strong>
                        {{
                          previousNode.config.subject || "Not Configured"
                        }}
                      </div>
                      <div class="action-label">
                        <strong>Preview Text: </strong>
                        {{
                          previousNode.config.previewText || "Not Configured"
                        }}
                      </div>
                    </div>
                  </template>
                  <template
                    v-else-if="previousNode.node_type === 'SendSplitTestEmail'"
                  >
                    <div class="action-label mb-2">
                      <strong>Tests: </strong>
                      {{ previousNode.config.testSize === 2 ? "A/B" : "A/B/C" }}
                    </div>
                    <div class="action-label">
                      <strong>Test Type:</strong>
                      {{
                        previousNode.config.testType === "subject"
                          ? "Subject Line"
                          : "Content"
                      }}
                    </div>
                  </template>
                  <template
                    v-else-if="previousNode.node_type === 'AutoResendEmail'"
                  >
                    <div
                      v-if="
                        previousNode.config.main_node_type &&
                          previousNode.config.main_node_type === 'SendEmail'
                      "
                    >
                      <div class="action-label mb-2">
                        <strong>Alternate Subject: </strong>
                        {{
                          previousNode.config.newSubject ||
                            "Not Configured"
                        }}
                      </div>
                      <div class="action-label">
                        <strong>Alternate Preview Text: </strong>
                        {{
                          previousNode.config.newPreviewText ||
                            "Not Configured"
                        }}
                      </div>
                    </div>
                    <div
                      v-if="
                        previousNode.config.main_node_type &&
                          previousNode.config.main_node_type === 'SendSplitTestEmail'
                      "
                    >
                      <div class="action-label my-2">
                        The alternate subject line and preview text will be
                        based on the email split test action above. The contact
                        will be sent the next best performing test that they
                        have not received. If the contact has received all tests
                        by this point in the campaign, then the split test
                        winner will be sent.
                      </div>
                    </div>
                    <div v-if="!previousNode.config.main_node_type">
                      <div class="action-label my-2">
                        You must connect an email action to this action in order
                        to configure it.
                      </div>
                    </div>
                  </template>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <div
        v-if="
          mainNode &&
            (mainNode.node_type === 'SendEmail' ||
              mainNode.node_type === 'SendSplitTestEmail')
        "
        class="d-flex justify-center"
      >
        <v-icon color="#241c15" size="26">
          mdi-arrow-down-bold
        </v-icon>
      </div>

      <template v-if="mainNode && mainNode.node_type === 'SendEmail'">
        <div class="remark-text mt-3 mb-2">
          Configure the subject line and preview text for this auto-resend
          below. We recommend trying out an alternate to the original values to
          try and get more email engagement.
        </div>

        <div class="notification-menu__header mt-4">
          Alternate Subject Line
        </div>
        <CustomTextInput
          :value="configuration.newSubject"
          class="mt--2"
          @input="
            $emit('update:configuration', {
              ...configuration,
              newSubject: $event,
            })
          "
        />
        <div class="notification-menu__header mt-4">
          Alternate Preview Text
        </div>
        <CustomTextInput
          :value="configuration.newPreviewText"
          :maxlength="150"
          class="mt--2"
          @input="
            $emit('update:configuration', {
              ...configuration,
              newPreviewText: $event,
            })
          "
        />
      </template>
      <div
        v-else-if="mainNode && mainNode.node_type === 'SendSplitTestEmail'"
        class="remark-text mt-3 mb-2"
      >
        The alternate subject line and preview text will be based on the email
        split test action above. The contact will be sent the next best
        performing test that they have not received. If the contact has received
        all tests by this point in the campaign, then the split test winner will
        be sent.
      </div>
    </template>

    <template v-else class="text-center">
      <v-alert type="error" class="text-center">
        You must connect an email action to this action in order to configure
        it.
      </v-alert>
    </template>
  </div>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "ActionAutoResendEmailConfiguration",
  components: {
    CustomTextInput,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      mainNode: null,
      previousNodes: [],
      hasMoreThanOnePreviousNode: false,
    };
  },
  computed: {
    hasPreviousNode() {
      return !!this.configuration.rootEmailNodeUUID;
    },
  },
  async mounted() {
    await this.loadPreviousNodes();
    this.$emit('update:configuration', {
      ...this.configuration,
      name: "Auto-Resend",
    })
  },
  methods: {
    getIconsForNode(node) {
      return node.ui.icons;
    },
    async loadPreviousNodes() {
      this.previousNodes = await this.$store.dispatch(
        "automation/getPreviousNodesForAutoResendAction",
        this.configuration.nuid
      );
      this.hasMoreThanOnePreviousNode = this.previousNodes?.length > 1;
      this.mainNode = this.previousNodes?.length > 0 ? this.previousNodes[0] : null;
    },
  },
};
</script>


<style lang="scss" scoped>
.action-title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: white;
}
.action-label {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: white;
}
.v-expansion-panels {
  .v-expansion-panel-header.configuration-panel-header {
    background: rgb(78, 152, 247) !important;
  }
  .v-expansion-panel-content {
    background: rgb(78, 152, 247) !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .configuration-panel-content {
      border-top: 1px solid white !important;
      padding-top: 10px;
    }
  }
}
.previous-node {
  border-radius: 5px;
}

.remark-text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: normal;
  color: #241c15;
  text-align: center;
}

.node-icon {
  filter: brightness(100);
}
</style>
